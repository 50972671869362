a,
button {
  background: 0 0;
  padding: 0;
}

a,
button,
select {
  cursor: pointer;
}

a,
ins {
  text-decoration: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
hr,
menu,
nav,
section {
  display: block;
}

a,
button,
hr {
  padding: 0;
}

.btn-blue,
.btn-blue2,
.btn-gradient {
  transition: all 0.3s ease-in-out;
}

.admin-table .table td,
.admin-table .table th,
.btn {
  white-space: nowrap;
}

@font-face {
  font-family: Roboto;
  src: url(../webfonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137) format('woff2'),
    url(../webfonts/Roboto/Regular/Roboto-Regular.woff?v=2.137) format('woff'),
    url(../webfonts/Roboto/Regular/Roboto-Regular.ttf?v=2.137) format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(../webfonts/Roboto/Italic/Roboto-Italic.woff2?v=2.137) format('woff2'),
    url(../webfonts/Roboto/Italic/Roboto-Italic.woff?v=2.137) format('woff'),
    url(../webfonts/Roboto/Italic/Roboto-Italic.ttf?v=2.137) format('ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url(../webfonts/Roboto/Medium/Roboto-Medium.woff2?v=2.137) format('woff2'),
    url(../webfonts/Roboto/Medium/Roboto-Medium.woff?v=2.137) format('woff'),
    url(../webfonts/Roboto/Medium/Roboto-Medium.ttf?v=2.137) format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(../webfonts/Roboto/MediumItalic/Roboto-MediumItalic.woff2?v=2.137) format('woff2'),
    url(../webfonts/Roboto/MediumItalic/Roboto-MediumItalic.woff?v=2.137) format('woff'),
    url(../webfonts/Roboto/MediumItalic/Roboto-MediumItalic.ttf?v=2.137) format('ttf');
  font-weight: 500;
  font-style: italic;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: middle;
  background: 0 0;
}

.keyTableCustomer p {
  margin-bottom: 0px !important;
  color: black;
}

.keyTableCustomer .typeAndCreate {
  color: #1f3349;
}

ins,
mark {
  background-color: #ff9;
  color: #000;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #1f3349;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
}

a {
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  color: #0070b7;
}

.btn,
img {
  vertical-align: middle;
}

a:focus,
button:focus {
  outline: 0;
}

label {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
strong {
  font-weight: 500;
}

button {
  border: none;
}

textarea {
  resize: none;
}

input::-webkit-input-placeholder {
  color: #ccc;
}

input::-moz-placeholder {
  color: #ccc;
}

input:-ms-input-placeholder {
  color: #ccc;
}

input:-moz-placeholder {
  color: #ccc;
}

mark {
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.admin-overview__chart .admin-overview__title h2 {
  margin-bottom: 0.5em;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

button,
input,
select,
textarea {
  vertical-align: middle;
}

input:disabled {
  pointer-events: none;
  background: #e5e8ef;
  border-color: transparent;
}

textarea:disabled {
  pointer-events: none;
  background: #e5e8ef;
  border-color: transparent;
}

*,
:after,
:before {
  box-sizing: border-box;
}

@keyframes opacityShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.btn-disable,
.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-cell {
  display: table-cell !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.italic {
  font-style: italic !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.semibold {
  font-weight: 600 !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.goong-account .goong-title {
  font-size: 16px !important;
  margin-bottom: 16px !important;
}

.goong-account .goong-subtitle {
  font-size: 16px !important;
  color: #172636;
  margin-bottom: 16px !important;
}

.goong-account .goong-icon {
  font-size: 25px !important;
}

@media (min-width: 1200px) {
  .goong-account .goong-container {
    display: flex !important;
    gap: 35px !important;
    margin-top: -30px;
  }

  .goong-account .goong-name {
    width: 600px;
  }

  .goong-account .goong-inform {
    width: 180px;
  }

  .goong-account .goong-contact {
    width: 290px;
  }
}

@media (max-width: 541px) {

  .goong-account .goong-container .goong-inform,
  .goong-account .goong-container .goong-contact {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {

  .goong-account .goong-container .goong-inform,
  .goong-account .goong-container .goong-contact {
    margin-top: 50px;
  }
}

@media (min-width: 541px) and (max-width: 1024px) {
  .goong-account .goong-container {
    margin-left: -5px;
  }
}

@media (max-width: 900px) {

  .goong-wrapper,
  .goong-wrapper-setWidth {
    min-height: calc(100vh - 130px) !important;
  }
}

@media (min-width: 992px) {
  .goong-login {
    min-height: calc(100vh - 185px) !important;
  }
}

@media (min-width: 850px) and (max-height: 777px) {
  .admin-overview .statis-item {
    min-height: 250px !important;
  }

  .statis-item__content {
    min-height: 150px !important;
  }
}

@media (max-width: 791px) {
  .admin-overview__chart .admin-overview__title {
    display: block !important;
    padding-bottom: 10px;
  }
}

@media (max-width: 1200px) {
  .goong-account__right {
    width: 100% !important;
    order: 1;
    margin-bottom: 30px;
  }

  .goong-account__left {
    width: 100% !important;
    order: 2;
  }

  .goong-login {
    min-height: 100vh !important;
  }

  .goong-footer {
    margin-left: 50px;
  }
}

@media (min-width: 650px) and (max-width: 860px) {
  .startEndStatistics.customer-statistic {
    display: block !important;
  }

  .goong-wrapper-setWidth .goong-statis__body .admin-header {
    display: block;
  }

  .startEndStatistics.customer-statistic .selectStatistiCustomer {
    margin-top: 10px;
    margin-left: 0px;
  }

  .goong-wrapper-setWidth .goong-statis__body .goong-chart__content {
    padding-left: 0px !important;
  }

  .goong-wrapper-setWidth .goong-statis__body .goong-pie_chart__content {
    padding-left: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .goong-wrapper-setWidth .profile-user-customer {
    width: calc(100vw - 10px) !important;
  }
}

@media (min-width: 650px) and (max-width: 1200px) {
  .goong-wrapper-setWidth .goong-login .form-sign {
    width: 100% !important;
  }

  .goong-wrapper-setWidth .container {
    width: calc(100vw - 320px);
    margin-right: auto;
    margin-left: auto;
    margin-left: 300px;
  }

  .goong-wrapper .container {
    width: calc(100vw - 30px) !important;
    padding-left: 60px !important;
    padding-right: 10px !important;
  }

  .goong-footer-setWidth .container {
    width: calc(100vw - 350px);
    margin-right: auto;
    margin-left: auto;
    margin-left: 300px;
  }

  .goong-footer-setWidth {
    padding: 20px 0;
  }

  .goong-footer-setWidth .goong-navbar__logo p {
    font-size: 16px;
    line-height: 19px;
    color: rgba(86, 101, 113, 0.5);
  }

  .goong-footer-setWidth .goong-navbar {
    justify-content: center;
  }

  .goong-footer-setWidth .goong-navbar__logo {
    width: 100%;
  }

  .goong-footer-setWidth .goong-navbar__logo h1 {
    display: none;
  }

  .goong-footer-setWidth .goong-navbar__logo p {
    text-align: center;
    margin-top: 10px;
  }

  .keys-box__table .table-responsive {
    overflow: auto;
  }

  .goong-wrapper-setWidth .goong-billing__body {
    display: block !important;
  }

  .goong-wrapper-setWidth .goong-billing__left,
  .goong-wrapper-setWidth .goong-billing__right {
    width: 100% !important;
  }

  .goong-wrapper-setWidth .goong-billing__right {
    margin-top: 10px;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (max-width: 1200px) {
  .goong-footer .goong-navbar__logo h1 {
    display: none;
  }
}

@media (max-width: 649px) {
  .goong-wrapper .container {
    margin-left: 50px !important;
  }
}

@media (max-width: 767px) {
  .faq-box__title {
    text-align: center;
  }

  .faq-box__bg {
    display: none;
  }

  .app-box__bg {
    display: none;
  }

  .app-box {
    padding-top: 40px;
  }

  .app-box__content {
    text-align: center;
  }

  .security-box__form .form-group .btn {
    width: 100%;
  }

  .billing-sidebar {
    margin-top: 30px;
  }

  .create-keys__radio .radio {
    margin-bottom: 15px;
  }

  .create-keys__radio .goong-tooltip {
    display: none;
  }

  .billing-list__table .table td,
  .billing-list__table .table th {
    white-space: nowrap;
  }

  .modal-dialog.modal-verify-code .modal-header {
    padding: 40px 15px 0;
  }

  .account-box__title {
    justify-content: center;
  }

  .keys-box__table table td,
  .keys-box__table table th {
    white-space: nowrap;
  }

  .category__bg {
    display: none;
  }

  .manual-docs__left {
    margin-bottom: 30px;
  }

  .docs-box__bg {
    display: none;
  }

  .goong-home__intro:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .goong-home__functions {
    background: 0 0 !important;
  }

  .end-box__text {
    margin-bottom: 15px;
  }

  .goong-home__intro {
    height: 360px;
  }

  .built-box__bg {
    display: none;
  }

  .pricing-box__bg {
    display: none;
  }

  .intro-box__title:before {
    left: 50%;
    transform: translateX(-50%);
  }

  .intro-box__map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .intro-box {
    padding-top: 40px;
  }

  .intro-box__content {
    text-align: center;
    position: relative;
  }

  .form-sign__img {
    display: none;
  }

  .goong-footer .goong-navbar__nav {
    display: none;
  }

  .goong-navbar__nav {
    display: none;
  }

  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }

  .goong-wrapper .container {
    margin-left: 50px;
  }

  .container {
    z-index: 2;
    position: relative;
  }

  .no-padding-xs {
    padding: 0 !important;
  }

  .no-margin-xs {
    margin: 0 !important;
  }

  /* .goong-footer .container {
    z-index: -1 !important;
  } */

  .goong-wrapper-setWidth .faq-customer-box .container {
    width: calc(100vw - 320px) !important;
  }

  .goong-wrapper .faq-customer-box {
    margin: 10px 10px 0px 60px !important;
  }

  .goong-wrapper-setWidth .faq-customer-box {
    padding-bottom: 10px;
    border: none !important;
    margin: 10px 10px 0px 20px !important;
  }

  .faq-customer-box .container {
    margin-left: 0px !important;
  }

  .customer-detail .profile-box {
    display: block !important;
  }

  .customer-detail .profile-box__flex {
    margin: 0px !important;
  }

  .customer-detail .profile-box__form {
    margin-top: 30px !important;
    width: 100% !important;
  }

  .customer-detail .profile-box__flex .form-group {
    width: 31% !important;
    margin-left: 10px;
  }

  .customer-detail .profile-box__avatar {
    margin: 0px auto !important;
  }

  .goong-wrapper .faq-customer-box .container {
    margin-left: 15px !important;
  }

  .goong-wrapper-setWidth .container {
    margin-left: 242px !important;
    width: calc(100vw - 242px) !important;
  }

  .goong-wrapper .faq-header {
    display: block !important;
  }

  .goong-wrapper .faq-background {
    display: none;
  }

  .goong-wrapper .faq-header .title-faqs {
    width: 100% !important;
  }

  .faq-header .title-faqs h1 {
    text-align: center;
  }

  .faq-customer-box {
    width: calc(100vw - 70px) !important;
  }

  .goong-wrapper .faq-customer-box .container {
    width: calc(100vw - 70px) !important;
  }
}

@media (max-width: 578px) {
  .customer-detail .profile-box__flex .form-group {
    width: 47% !important;
  }
}

.showEditInfoAdmin {
  width: calc(100vw - 148px);
  padding: 5px 0px;
  margin-top: 10px;
  border-radius: 10px;
  font-size: 15px;
  border: 1px solid grey;
  margin-left: 15px;
  display: none;
}

@media (max-width: 483px) {
  .customer-detail .profile-box__flex .form-group {
    width: 94% !important;
  }

  .stateLayoutFormEdit,
  .btn-save-customer-userManagement {
    display: none !important;
  }

  .customer-detail .profile-box__avatar {
    width: 100% !important;
  }

  .showEditInfoAdmin {
    display: block;
  }

  .save-moblie-userManagement {
    text-align: center;
  }
}

@media (min-width: 484px) {
  .save-moblie-userManagement {
    display: none !important;
  }
}

@media (max-width: 378px) {
  .customer-detail .admin-header {
    display: block !important;
  }

  .customer-detail .admin-header__btn {
    text-align: center;
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .no-padding-sm {
    padding: 0 !important;
  }

  .no-margin-sm {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .no-padding-md {
    padding: 0 !important;
  }

  .no-margin-md {
    margin: 0 !important;
  }
}

.white-1 {
  color: #fff !important;
}

.black-1 {
  color: #000 !important;
}

.orange {
  color: #fe894c !important;
}

.blue-2 {
  color: #469af7 !important;
}

.blue-3 {
  color: #48a0f8 !important;
}

.medium {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.size-10 {
  font-size: 10px !important;
}

.size-12 {
  font-size: 12px !important;
}

.size-14 {
  font-size: 14px !important;
}

.size-16 {
  font-size: 16px !important;
}

.size-18 {
  font-size: 18px !important;
}

.size-20 {
  font-size: 20px !important;
}

.size-22 {
  font-size: 22px !important;
}

.size-24 {
  font-size: 24px !important;
}

.size-28 {
  font-size: 28px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.o-hidden {
  overflow: hidden !important;
}

.btn-upload,
.modal,
.modal-open {
  overflow: hidden;
}

.text {
  font-size: 18px;
  line-height: 30px;
  color: #1f3349;
}

.flex-box {
  padding: 0 0 30px;
  border-bottom: 1px solid #efefef;
  text-align: center;
}

@media (min-width: 992px) {
  .flex-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 0 0 15px;
  }
}

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  display: none;
}

.flex-box__left {
  margin: 0 0 15px;
}

.flex-box__left p {
  font-size: 14px;
  line-height: 21px;
  color: #566571;
  margin: 0;
}

.flex-box__left p span {
  color: #469af7;
}

.flex-box__right .btn {
  text-transform: initial;
}

.flex-box__text_amout {
  font-weight: 600;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 25px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(31, 51, 73, 0.6);
}

.btn,
.radio label {
  font-size: 16px;
  cursor: pointer;
}

.checkbox input[type='checkbox']+label:before {
  content: '';
  width: 18px;
  height: 18px;
  line-height: 18px;
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.checkbox input[type='checkbox']:checked+label:before {
  content: '';
  background: url(../images/i-check.svg) center center no-repeat;
}

.radio label {
  position: relative;
  margin-bottom: 0;
  padding-left: 33px;
}

.radio input[type='radio']+label:before {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
}

.btn,
.btn-upload {
  position: relative;
  display: inline-block;
}

.radio input[type='radio']:checked+label:before {
  border: 4px solid #f0f2f8;
  box-shadow: 0 0 0 1px #f0f2f8;
  background-color: #3a93f9;
}

.btn {
  font-weight: 500;
  line-height: 19px;
  padding: 13px 20px;
  border: none;
  border-radius: 10px;
  text-align: center;
  color: #1f3349;
  touch-action: manipulation;
  user-select: none;
}

.btn-gradient {
  background: linear-gradient(348.64deg, #3197f8 1.17%, #51c5fe 99.21%);
  box-shadow: 0 22px 34px rgba(47, 130, 218, 0.197225);
  color: #fff;
}

@media (min-width: 992px) {
  .flex-box__left {
    margin: 0;
  }

  .btn.btn-lg {
    font-size: 24px;
    line-height: 28px;
    padding: 22px;
  }

  .btn-gradient:hover {
    background-size: 300% 100%;
  }
}

.btn-blue {
  background: #469af7;
  color: #fff;
}

@media (min-width: 992px) {
  .btn-blue:hover {
    background: #2e6be6;
  }
}

.btn-blue2 {
  background: #469af7;
  color: #fff;
}

@media (min-width: 992px) {
  .btn-blue2:hover {
    background: #2e6be6;
  }
}

.btn-red {
  background: #e75252;
  color: #fff;
}

.btn-red:hover {
  background: #e02f2f;
}

.btn-round {
  border-radius: 60px;
}

.btn-outline {
  background-color: #fff;
  border: 1px solid #3a93f9;
  color: #3a93f9;
}

.btn-disabled {
  cursor: default;
}

.btn-loading .fa-spinner {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-upload input[type='file'] {
  position: absolute;
  left: -71px;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.form-group {
  margin-bottom: 24px;
}

.form-group>label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.form-group>label span {
  color: red;
  padding-left: 3px;
}

.form-control {
  border: 1px solid #f0f2f8;
  border-radius: 10px;
  padding: 13px 10px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #1f3349;
  background: rgba(244, 246, 251, 0.8);
  padding-right: 30px;
}

.form-controll {
  border: 1px solid #f0f2f8;
  border-radius: 10px;
  padding: 1px 10px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #1f3349;
  background: rgba(244, 246, 251, 0.8);
  padding-right: 20px;
}

.selectType {
  display: flex;
  margin-left: 7px;
  align-items: center;
  height: 50px !important;
}

.selectTypee {
  display: flex;
  margin-left: 7px;
  align-items: center;
  height: 44px !important;
}

.form-control:active,
.form-control:focus {
  border-color: #3a93f9;
}

[data-tip]:after,
[data-tip]:before {
  position: block;
  /* background-color: #d0011b; */
}

.border-red {
  border-color: #d0011b !important;
}

.form-control:focus {
  color: #000;
  outline: 0;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #b4b3b3;
}

.form-control::-moz-placeholder {
  color: #b4b3b3;
}

.form-control:-ms-input-placeholder {
  color: #b4b3b3;
}

.form-control:-moz-placeholder {
  color: #b4b3b3;
}

[data-tip] {
  position: relative;
}

[data-tip]:before {
  content: '';
  left: 9px;
  top: calc(100% + 2px);
  z-index: 2;
  /* border-top: 1px solid #d0011b; */
  /* border-left: 1px solid #d0011b; */
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
}

[data-tip]:after {
  content: attr(data-tip);
  top: calc(100% + 3px);
  left: 0;
  z-index: 1;
  padding: 5px 10px;
  display: block;
  color: #d0011b;
  font-size: 12px;
  border-radius: 6px;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog,
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog {
  width: calc(100% - 10px);
  margin: 60px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #fff;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in,
.fade.show {
  opacity: 1;
}

.modal-header {
  position: relative;
  padding: 32px 15px;
}

.modal-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 480px;
  }

  .modal-header {
    padding: 24px;
  }

  .modal-title {
    padding: 0 40px;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .modal-close {
    top: 35px;
    right: 35px;
  }

  .modal-dialog.modal_lg {
    width: 750px;
  }
}

.modal-close:focus {
  outline: 0;
}

.modal-close svg {
  display: block;
}

.modal-body,
.modal-footer {
  padding: 15px;
}

.modal-footer .btn {
  margin: 0 7px;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: #172637;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.in,
.modal-backdrop.show {
  opacity: 0.9;
}

.home-title {
  margin-bottom: 30px;
  text-align: center;
}

.home-title h1,
.home-title h2,
.home-title h3 {
  font-size: 28px;
  font-weight: 500;
  color: #1f3349;
}

@media (min-width: 992px) {

  .home-title h1,
  .home-title h2,
  .home-title h3 {
    font-size: 38px;
    line-height: 45px;
  }
}

.heading {
  margin: 0 0 15px;
}

.heading h5 {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #1f3349;
}

.slick-box {
  padding-bottom: 20px;
  margin: 0 -15px;
}

@media (min-width: 992px) {
  .slick-box {
    padding-bottom: 40px;
  }
}

.slick-box__item {
  padding: 0 15px;
  cursor: auto;
  overflow: hidden;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}

.table tr th {
  vertical-align: bottom;
  text-align: left;
}

.table tr td {
  vertical-align: top;
}

.table td,
.table th {
  padding: 15px;
  border-bottom: 1px solid #efefef;
}

.table.table-bordered td,
.table.table-bordered th {
  border: 1px solid #efefef;
}

.table.table-bordered th {
  background-color: rgba(240, 242, 248, 0.6);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive .tb-width {
  width: 100%;
}

.tbCl-bill-key .admin-pagination .table-length,
.tbCl-bill-key .admin-pagination .pagination {
  position: relative !important;
}

.tbCl-bill-key {
  position: relative;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-fixed {
  table-layout: fixed;
}

.table-fixed thead tr {
  display: flex;
}

.table-fixed thead th {
  width: 10%;
}

.table-fixed tbody {
  display: block;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.table-fixed tbody::-webkit-scrollbar {
  width: 6px;
}

.table-fixed tbody::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 16px;
}

.table-fixed tbody::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 16px;
}

.table-fixed tbody::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}

.table-fixed tbody tr {
  display: flex;
  margin-right: -6px;
}

.table-fixed tbody tr td {
  width: 10%;
}

progress {
  display: block;
  width: 100%;
  height: 10px;
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
}

progress::-webkit-progress-bar {
  background: 0 0;
}

progress::-webkit-progress-value {
  border-radius: 30px;
  background: #469af7;
  border-color: #469af7;
}

progress::-moz-progress-bar {
  border-radius: 30px;
  background: #469af7;
  border-color: #469af7;
}

progress[value]::-ms-fill {
  border-radius: 30px;
  background: #469af7;
  border-color: #469af7;
}

.dropdown-menu,
.goong-tooltip__box {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  z-index: 10;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  padding: 0 15px;
}

.dropdown-toggle span {
  padding-right: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100% !important;
  right: 0;
  width: 100%;
  background-color: #fff;
  padding: 5px 0;
  transition: 0.3s;
  display: none;
  transform: none !important;
}

.dropdown-item {
  padding: 0 15px;
}

.dropdown-item:hover {
  background: #f5f5f5;
}

.dropdown-item:last-child .dropdown-link {
  border-bottom: none;
}

.dropdown-link {
  display: block;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid #efefef;
  color: #566571;
}

.dropdown-open .dropdown-toggle>svg {
  transform: rotate(180deg);
}

.dropdown-open .dropdown-menu {
  display: block;
}

.dropdown-link-hover:hover {
  color: #469af7;
}

.goong-tooltip {
  position: relative;
}

.goong-tooltip:hover .goong-tooltip__box {
  opacity: 1;
  visibility: visible;
}

.goong-tooltip__box {
  background: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  padding: 15px 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.goong-tooltip__box .medium {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 10px;
  color: #1f3349;
}

.goong-tooltip__box p {
  font-size: 12px;
  line-height: 18px;
  color: #566571;
  margin: 0;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-item {
  padding: 0 2px;
}

.pagination-item.active .pagination-link {
  background-color: #469af7;
  color: #fff;
}

.pagination-item.next .pagination-link {
  font-size: 14px;
  line-height: 16px;
  width: auto;
  height: auto;
}

.pagination-link {
  font-size: 12px;
  line-height: 14px;
  color: #469af7;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.button-switch {
  height: 1.875em;
  position: relative;
  width: 4.5em;
  font-size: 1.3em;
}

.button-switch .lbl-off,
.button-switch .lbl-on {
  cursor: pointer;
  display: block;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  position: absolute;
  top: 0.3em;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
  margin: 0;
}

.button-switch .lbl-off {
  right: 0.8em;
}

.button-switch .lbl-on {
  color: #fefefe;
  opacity: 0;
  left: 0.4375em;
}

.button-switch .switch {
  height: 0;
  font-size: 1em;
  left: 0;
  line-height: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 0;
  margin: 0;
}

.button-switch .switch:after,
.button-switch .switch:before {
  content: '';
  font-size: 1em;
  position: absolute;
}

.button-switch .switch:before {
  border: 1px solid #f0f2f8;
  border-radius: 1.25em;
  background: rgba(244, 246, 251, 0.8);
  height: 1.875em;
  left: -0.25em;
  top: -0.1875em;
  transition: background-color 0.25s ease-out 0.1s;
  width: 4.5em;
}

.button-switch .switch:after {
  border-radius: 50%;
  background: #e5e8f0;
  height: 1.5em;
  transform: translate(0, 0);
  transition: transform 0.25s ease-out 0.1s;
  width: 1.5em;
}

.button-switch .switch:checked:after {
  transform: translate(2.5em, 0);
}

.button-switch .switch:checked~.lbl-off {
  opacity: 0;
}

.button-switch .switch:checked~.lbl-on {
  opacity: 1;
}

.button-switch .switch#switch-blue:checked:before {
  background: #3a93f9;
}

.goong-wrapper .container,
.goong-header .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {

  .goong-wrapper .container,
  .goong-header .container {
    width: 750px;
  }
}

@media (min-width: 992px) {

  .goong-wrapper .container,
  .goong-header .container,
  .goong-wrapper .container .faq-customer-box .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {

  .goong-wrapper.container,
  .goong-header .container,
  .faq-customer-box .container {
    width: 1170px;
  }
}

@media (max-width: 550px) {
  .create-keys__item.create-keys__table {
    overflow-x: auto !important;
    margin: 0px 0px 20px 0px !important;
    width: 100%;
    padding: 0px !important;
    min-height: 150px;
  }

  .create-keys__item.create-keys__table .table {
    min-width: 400px !important;
  }

  .create-keys__item.create-keys__table .btn-add {
    bottom: unset !important;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

@media (min-width: 768px) {

  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {

  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
}

.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
  display: none !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
}

@media (min-width: 1200px) {

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .visible-lg {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.goong-header {
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .goong-header {
    padding: 24px 0;
  }
}

.goong-navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.goong-navbar__logo h1 a {
  display: block;
}

.goong-navbar__logo img {
  width: 127px;
}

.goong-navbar__toggle {
  background: 0 0;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goong-navbar__toggle:focus {
  outline: 0;
}

@media (min-width: 768px) {
  .goong-navbar__toggle {
    display: none;
  }

  .goong-navbar__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -15px;
  }
}

.goong-navbar__item.active .goong-navbar__link {
  color: #1f3349;
  font-weight: 500;
}

.active .goong-navbar__link-dashboard {
  color: #1f3349;
  font-weight: 500;
}

.goong-navbar__link {
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: rgba(31, 51, 73, 0.5);
  transition: all 0.3s;
  padding: 10px 15px;
  background: 0 0;
  border: none;
  cursor: pointer;
}

.goong-navbar__link-dashboard {
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: rgba(31, 51, 73, 0.5);
  transition: all 0.3s;
  padding-right: 14px;
  padding-bottom: 7px;
  background: 0 0;
  border: none;
  cursor: pointer;
  font-weight: 400;
}

.form-sign,
.goong-footer {
  background-color: #fff;
}

.goong-navbar__link:hover {
  text-decoration: none;
  color: #1f3349;
}

.admin-table .table tbody td>a:hover,
.docs-nav__link:hover,
.docs-nav__sub__link:hover,
.faq-box__list li button:hover,
.form-sign__pass:hover {
  text-decoration: underline;
}

.goong-navbar__link:focus {
  outline: 0;
}

.goong-navbar__account {
  padding-left: 10px;
}

.goongLogoMenu {
  width: auto !important;
  height: 40px !important;
  border-radius: 0px !important;
}

@media (min-width: 992px) {
  .goong-navbar__account {
    padding-left: 40px;
  }
}

@media (max-width: 992px) {
  .goong-navbar__link .emailUserNavbar {
    display: none;
  }

  .goong-navbar__account .goong-navbar__link {
    padding: 0px !important;
  }

  .goong-wrapper-setWidth .faq-header {
    display: block !important;
  }

  .goong-wrapper-setWidth .faq-background {
    display: none;
  }

  .goong-wrapper-setWidth .faq-header .title-faqs {
    width: 100% !important;
  }

  .goong-wrapper-setWidth .faq-header .title-faqs h1 {
    text-align: center;
  }
}

.emailUserNavbar {
  width: 200px;
}

/* .emailUserNavbar .emailUserNavbar_info .item {
  display: flex;
  justify-content: flex-end;

} */
.emailUserNavbar .emailUserNavbar_info .item_package {
  display: flex;
  justify-content: flex-end;
}

.emailUserNavbar .emailUserNavbar_info .item_package .package {
  display: flex;
  padding: 2px 8px;
  height: 18px;
  border: 1px solid #469af7;
  border-radius: 4px;
  justify-content: space-evenly;

  font-size: 12px;
}

.emailUserNavbar .emailUserNavbar_info .item .mail {
  color: #1f3349;
  font-family: Roboto;
  font-weight: 400;
  line-height: 22px;
}

.emailUserNavbar .emailUserNavbar_info .item_package .package .user_plan {
  color: #469af7;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  line-height: 14px;
}

.goong-navbar__account .goong-navbar__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.goong-navbar__account .goong-navbar__link .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 15px;
}

.goong-navbar__account .dropdown-menu {
  width: 130px;
  left: auto !important;
  right: 0;
}

.goong-navbar__account .dropdown-link {
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #566571;
}

.goong-navbar__account .dropdown-link span,
.goong-navbar__account .dropdown-link svg {
  vertical-align: middle;
}

.goong-navbar__account .dropdown-link span {
  padding-left: 15px;
}

.goong-footer {
  padding: 30px 0;
}

@media (max-width: 1200px) {
  .goong-footer {
    padding: 30px 15px;
    /* padding: 30px 15px 30px 0px; */
  }
}

@media (min-width: 500px) {
  .goong-footer {
    padding: 30px 15px;
    /* padding: 30px 15px 30px 0px; */
  }
}

.goong-footer .goong-navbar__logo p {
  font-size: 16px;
  line-height: 19px;
  color: rgba(86, 101, 113, 0.5);
}

@media (max-width: 1199px) {
  .goong-footer .goong-navbar {
    justify-content: center;
  }

  .goong-footer .goong-navbar__logo {
    width: 100%;
  }

  .goong-footer .goong-navbar__logo p {
    text-align: center;
    margin-top: 10px;
  }

  .goong-navbar__account {
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .goong-footer .goong-navbar__logo {
    display: flex;
    align-items: center;
  }

  .goong-footer .goong-navbar__logo p {
    padding-left: 25px;
    margin: 0;
  }
}

@media (max-width: 1199px) {
  .goong-footer .goong-navbar__logo h1 a {
    justify-content: center;
  }
}

.goong-footer .goong-navbar__link {
  color: #1f3349;
}

@media (min-width: 768px) {
  .form-sign {
    display: flex;
    justify-content: space-between;
  }

  .form-sign__img {
    width: 170px;
  }
}

.form-sign__img img {
  width: 100%;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

.form-sign__content {
  padding: 40px 30px 40px 20px;
}

@media (min-width: 768px) {
  .form-sign__content {
    width: calc(100% - 170px);
  }

  .form-sign__line>p {
    white-space: nowrap;
  }
}

.form-sign__title {
  font-size: 38px;
  line-height: 30px;
  margin: 0 0 25px;
}

.form-sign__form .form-control {
  width: 100%;
}

.form-sign__border {
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  text-align: center;
  margin: 10px 0 25px;
}

.form-sign__border span {
  display: inline-block;
  padding: 0 10px;
  background-color: #fff;
  transform: translateY(11px);
}

.form-sign__social .btn {
  width: 100%;
  margin-bottom: 15px;
}

.form-sign__social .btn:last-child {
  margin: 0;
}

.form-sign__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-sign__line>p {
  font-size: 12px;
  line-height: 14px;
  color: rgba(31, 51, 73, 0.6);
  margin: 0;
}

.form-sign__pass {
  background: 0 0;
  border: none;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
  color: #48a0f8;
  cursor: pointer;
}

.form-sign__pass:focus {
  outline: 0;
}

.form-sign__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -8px;
}

.form-sign__btn .d-block {
  width: 50%;
  padding: 0 8px;
}

.form-sign__btn .d-block .btn {
  width: 100%;
}

.form-sign_forget .form-sign__btn .d-block {
  margin: auto;
}

.modal-dialog.modal-login {
  width: calc(100% - 10px);
}

.modal-dialog.modal-login .modal-content {
  border: none;
  border-radius: 10px;
}

.modal-dialog.modal-login .modal-body {
  padding: 0;
}

.goong-login {
  padding: 50px 0;
  background-color: #f4f6fb;
}

.goong-login .form-sign {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

@media (min-width: 768px) {
  .modal-dialog.modal-login {
    width: 540px;
  }

  .goong-login .form-sign {
    width: 600px;
    margin: auto;
  }

  .goong-login .form-sign-up {
    width: 660px;
  }
}

.menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  visibility: hidden;
}

.menu-mobile__backdrop,
.menu-mobile__main {
  position: absolute;
  top: 0;
  transition: 0.4s ease-in-out;
  visibility: hidden;
  bottom: 0;
  left: 0;
}

.menu-mobile__main {
  width: calc(100% - 100px);
  z-index: 1;
  background-color: #fff;
  transform: translateX(-100%);
}

.menu-mobile__header {
  /* background-image: linear-gradient(348.64deg, #3197F8 1.17%, #51C5FE 99.21%) */
  background-color: #469af7;
}

.menu-mobile__login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 15px;
}

.menu-mobile__login img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.error-404,
.error-404 svg {
  width: 100%;
}

.menu-mobile__login span {
  flex: 1 1 auto;
  padding-left: 10px;
  font-size: 20px;
  line-height: 16px;
  font-weight: 500;
  color: #fff;
}

.menu-mobile__login svg {
  transform: rotate(-90deg);
}

.menu-mobile__content {
  height: calc(100% - 62px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 15px;
}

.menu-mobile__list {
  border-bottom: 5px solid #f2f2f2;
}

.menu-mobile__list:last-child {
  border-bottom: none;
}

.menu-mobile__item {
  border-bottom: 1px solid #f2f2f2;
  font-weight: 500;
}

.menu-mobile__item.active .menu-mobile__link {
  color: #3a93f9;
  font-weight: 500;
}

.menu-mobile__link {
  width: 100%;
  display: block;
  font-size: 14px;
  color: #1f3349;
  text-align: left;
  font-weight: 500;
}

.menu-mobile__backdrop {
  right: 0;
  background-color: rgba(23, 38, 55, 0.9);
  opacity: 0;
}

#fixed-menu-mobile {
  position: fixed !important;
  top: 0px !important;
}

.open-menu-mobile {
  overflow: hidden;
}

.open-menu-mobile .menu-mobile {
  visibility: visible;
}

.open-menu-mobile .menu-mobile__main {
  transform: translateX(0);
  visibility: visible;
}

.open-menu-mobile .menu-mobile__backdrop {
  visibility: visible;
  opacity: 1;
}

.goong-body {
  background-color: #f4f6fb;
}

.goong-body .goong-header {
  box-shadow: none;
}

.goong-body .goong-footer {
  background-color: transparent;
}

.goong-body .goong-footer .goong-navbar__logo h1 {
  display: none;
}

.error-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}

.error-404__text {
  margin: 20px 0;
  text-align: center;
}

.error-404__text p {
  font-size: 22px;
}

.error-404__btn .btn {
  background-color: #469af7;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.error-404__btn .btn:hover {
  background-color: #2e6be6;
}

.error-404 #g6219 {
  -webkit-transform-origin: 85px 4px;
  transform-origin: 85px 4px;
  -webkit-animation: an1 12s 0.5s infinite ease-out;
  animation: an1 12s 0.5s infinite ease-out;
}

@-webkit-keyframes an1 {

  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  15% {
    -webkit-transform: rotate(-2.5deg);
    transform: rotate(-2.5deg);
  }

  25%,
  65% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  35%,
  55% {
    -webkit-transform: rotate(-1.5deg);
    transform: rotate(-1.5deg);
  }

  45% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  75% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  85% {
    -webkit-transform: rotate(2.5deg);
    transform: rotate(2.5deg);
  }

  95% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}

@keyframes an1 {

  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  15% {
    -webkit-transform: rotate(-2.5deg);
    transform: rotate(-2.5deg);
  }

  25%,
  65% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  35%,
  55% {
    -webkit-transform: rotate(-1.5deg);
    transform: rotate(-1.5deg);
  }

  45% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  75% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  85% {
    -webkit-transform: rotate(2.5deg);
    transform: rotate(2.5deg);
  }

  95% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}

@media (min-width: 768px) {
  .intro-box {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }

  .intro-box__content {
    width: 40%;
    padding: 40px 15px 0;
  }
}

@media (max-width: 991px) {
  .intro-box__content .p3 {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .goong-wrapper {
    min-height: calc(100vh - 203.6px);
  }

  .intro-box__content {
    width: 33.333%;
    padding: 60px 15px 0;
  }

  .intro-box__content .p3 {
    font-size: 18px;
    line-height: 30px;
  }
}

.intro-box__title {
  margin-bottom: 30px;
  position: relative;
}

.intro-box__title:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 212px;
  height: 4px;
  background-color: #1f3349;
}

@media (max-width: 991px) {
  .intro-box__title .p1 {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .intro-box__title .p1 {
    font-size: 45px;
  }
}

@media (min-width: 1200px) {
  .intro-box__title .p1 {
    font-size: 55px;
    line-height: 80px;
  }
}

.intro-box__title .p1 span {
  font-weight: 500;
}

.intro-box__title .p2 {
  padding-bottom: 20px;
  font-size: 28px;
}

@media (min-width: 992px) {
  .intro-box__title .p2 {
    font-size: 38px;
  }
}

.intro-box__btn {
  margin: 30px auto 0;
  width: 290px;
}

.intro-box__btn .btn {
  width: 100%;
  border-radius: 20px;
}

@media (min-width: 768px) {
  .intro-box__btn {
    width: 100%;
  }

  .intro-box__map {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: calc((100% - 720px) / 2 + 432px);
    padding: 0 0 0 15px;
  }

  .services-box {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
}

@media (min-width: 992px) {
  .intro-box__map {
    width: calc((100% - 960px) / 2 + 640px);
  }
}

@media (min-width: 1200px) {
  .intro-box__title .p2 {
    font-size: 48px;
    line-height: 80px;
  }

  .intro-box__map {
    width: calc((100% - 1140px) / 2 + 760px);
  }
}

.intro-box__map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 0 0 80px;
}

.services-box {
  font-size: 18px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .services-box__left {
    width: 40%;
    padding: 0 15px;
  }
}

@media (min-width: 992px) {
  .services-box__left {
    width: 33.333%;
  }
}

@media (min-width: 768px) {
  .services-box__right {
    width: 60%;
    padding: 0 15px;
  }
}

@media (min-width: 992px) {
  .services-box__right {
    width: 66.666%;
  }
}

.services-box__right p button {
  font-size: 18px;
  vertical-align: baseline;
  color: #48a0f8;
}

.services-box__iframe {
  display: flex;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #efefef;
  margin-top: 10px;
}

.services-box__iframe__item {
  width: 50%;
  padding: 0 3px;
}

.services-box__iframe__item iframe {
  width: 100%;
  height: 245px;
}

.services-box__iframe__item p {
  font-size: 16px;
  line-height: 19px;
  color: rgba(86, 101, 113, 0.5);
  margin: 5px 0 0;
}

.functions-box__content p,
.pricing-box__content>p {
  line-height: 30px;
  font-size: 18px;
}

@media (min-width: 768px) {
  .modal-dialog.modal-view-map {
    width: 90%;
    height: 90%;
  }
}

.modal-dialog.modal-view-map .modal-content {
  height: 100%;
}

@media (min-width: 768px) {
  .modal-dialog.modal-view-map .modal-body {
    padding: 15px 35px;
    height: calc(100% - 60px);
  }

  .modal-dialog.modal-view-map .services-box__iframe {
    height: 100%;
    margin: 0;
  }

  .modal-dialog.modal-view-map .services-box__iframe__item iframe {
    height: 100%;
  }

  .pricing-box {
    display: flex;
    justify-content: flex-end;
    margin: 0 -15px;
  }

  .pricing-box__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    width: calc((100% - 720px) / 2 + 288px);
    padding: 0 15px 0 0;
  }
}

@media (min-width: 992px) {
  .pricing-box__bg {
    width: calc((100% - 960px) / 2 + 320px);
  }
}

@media (min-width: 1200px) {
  .pricing-box__bg {
    width: calc((100% - 1140px) / 2 + 375px);
  }
}

.pricing-box__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 80px 80px 0;
}

@media (min-width: 768px) {
  .pricing-box__content {
    width: 60%;
    padding: 0 15px;
  }

  .pricing-box__content .home-title {
    text-align: left;
  }
}

.pricing-box__table {
  margin: 30px 0 0;
  border: 1px solid #eee;
  border-radius: 24px;
  padding: 15px;
}

@media (min-width: 992px) {
  .pricing-box__content {
    width: 66.666%;
  }

  .pricing-box__table {
    padding: 30px 30px 15px;
  }
}

.pricing-box__table .a1 {
  display: block;
  text-align: center;
  padding: 15px 0 0;
}

.functions-box__content p {
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .functions-box__content p {
    margin-bottom: 30px;
  }
}

.functions-box__content p:last-child {
  margin-bottom: 0;
}

.functions-box__content p span {
  font-weight: 500;
}

@media (min-width: 768px) {
  .functions-box__content {
    width: 50%;
    padding-top: 50px;
  }

  .functions-box__content .home-title {
    text-align: left;
  }

  .data-box {
    display: flex;
    margin: 0 -15px;
  }
}

.data-box__item {
  text-align: center;
  padding: 15px 0;
}

@media (min-width: 768px) {
  .data-box__item {
    width: 25%;
    padding: 0 15px;
  }
}

.data-box__icon {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media (min-width: 1200px) {
  .data-box__item {
    text-align: left;
  }

  .data-box__content {
    padding-left: 35px;
  }

  .data-box__icon {
    justify-content: flex-start;
  }
}

.data-box__title {
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
  margin: 10px 0 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .data-box__title {
    line-height: 26px;
    height: 52px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.data-box__text {
  font-size: 16px;
  line-height: 26px;
  color: #566571;
}

@media (min-width: 768px) {
  .built-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -15px;
  }

  .built-box__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    width: 50%;
    padding: 0 15px 0 0;
  }
}

.built-box__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 80px 80px 0;
}

@media (min-width: 768px) {
  .built-box__content {
    width: 50%;
    padding: 0 15px;
  }

  .built-box__content .home-title {
    text-align: left;
  }
}

.built-box__content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
}

.built-box__content p:last-child {
  margin-bottom: 0;
}

.end-box {
  text-align: center;
}

@media (min-width: 768px) {
  .end-box {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .goong-home__intro {
    min-height: 342px;
  }
}

@media (min-width: 1200px) {
  .end-box {
    justify-content: center;
    margin: 0 -15px;
  }

  .end-box__text {
    padding: 0 15px;
  }
}

.end-box__text p {
  font-size: 22px;
  line-height: 38px;
}

.end-box__btn {
  width: 290px;
  margin: auto;
}

@media (min-width: 1200px) {
  .end-box__btn {
    width: 350px;
    margin: 0;
    padding: 0 15px;
  }
}

.goong-home__built,
.goong-home__data,
.goong-home__functions,
.goong-home__intro,
.goong-home__services {
  margin-bottom: 100px;
}

.end-box__btn .btn {
  width: 100%;
}

.goong-home__intro {
  position: relative;
}

@media (min-width: 992px) {
  .goong-home__intro {
    min-height: 494px;
  }
}

.goong-home__pricing {
  position: relative;
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .goong-home__functions {
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

@media (min-width: 1200px) {
  .goong-home__intro {
    min-height: 600px;
  }

  .goong-home__functions {
    padding-bottom: 100px;
  }
}

.goong-home__built {
  background: linear-gradient(0deg, rgba(235, 239, 247, 0.4) 3.45%, #f9fafc 100%);
  position: relative;
  padding: 30px 0;
}

@media (min-width: 768px) {
  .goong-home__built {
    padding: 50px 0;
  }
}

@media (min-width: 992px) {
  .goong-home__built {
    padding: 100px 0;
  }

  .goong-docs__guides {
    margin-top: -110px;
    position: relative;
  }
}

.goong-home__end {
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .goong-home__end {
    padding: 30px 0 100px;
  }
}

.goong-docs {
  padding-bottom: 50px;
}

.goong-docs__intro {
  position: relative;
}

@media (min-width: 768px) {
  .docs-box {
    display: flex;
    justify-content: flex-start;
    margin: 0 -15px;
  }

  .docs-box__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 50%;
    padding: 0 0 0 15px;
  }
}

.docs-box__bg img {
  width: 100%;
  height: 100%;
}

.docs-box__content {
  padding: 40px 0;
}

@media (min-width: 768px) {
  .docs-box__content {
    width: 50%;
    padding: 40px 15px;
  }

  .docs-box .home-title {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .docs-box__content {
    padding: 80px 15px 110px;
  }
}

@media (min-width: 768px) {
  .guides-box {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .guides-box__col {
    width: 50%;
    padding: 0 15px;
  }
}

.guides-box__item {
  box-shadow: 0 12px 14px rgba(0, 0, 0, 0.05);
  border-radius: 0 40px 40px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 45px;
}

.account-box,
.account-sidebar,
.keys-box {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.02);
}

@media (min-width: 992px) {
  .guides-box__col {
    width: 25%;
  }

  .guides-box__item {
    margin: 0;
  }
}

.guides-box__title {
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

.guides-box__title h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 0 0 15px 10px;
}

.guides-box__btn {
  margin: 0 auto -30px;
}

.guides-box__btn .btn {
  width: 100%;
  padding: 15px 20px;
}

.guides-box__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  padding: 20px 0 20px 15px;
  color: #404041;
}

.docs-nav__item {
  padding-bottom: 5px;
}

.docs-nav__link {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #48a0f8;
}

.docs-nav__sub {
  padding-left: 15px;
}

.docs-nav__sub__link {
  font-size: 16px;
  line-height: 26px;
  color: #48a0f8;
}

.manual-docs {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .manual-docs {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }

  .manual-docs__left {
    width: 40%;
    padding: 0 15px;
  }
}

@media (min-width: 992px) {
  .manual-docs__left {
    width: 33.333%;
  }
}

.manual-docs__sidebar .a-back {
  font-size: 16px;
  line-height: 19px;
  color: #566571;
  display: flex;
  align-items: center;
}

.manual-docs__sidebar .a-back svg {
  margin-right: 5px;
}

.manual-docs__icon {
  padding: 35px 0 30px;
}

@media (min-width: 768px) {
  .manual-docs__right {
    width: 60%;
    padding: 0 15px;
  }
}

@media (min-width: 992px) {
  .manual-docs__right {
    width: 66.667%;
  }
}

.manual-docs__img {
  margin-bottom: 25px;
}

.manual-docs__img img {
  width: 100%;
  border-radius: 0 80px 0 0;
}

.manual-docs__item {
  margin-bottom: 50px;
}

.manual-docs__item.display-3d .manual-docs__code p span {
  display: inline-block;
  padding-left: 3px;
}

.manual-docs__content p {
  font-size: 16px;
  line-height: 26px;
  color: #566571;
}

.manual-docs__box {
  margin-bottom: 15px;
}

.manual-docs__box .p1 {
  margin: 0 0 10px;
}

.manual-docs__code {
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
  padding: 5px 15px;
}

.manual-docs__code p {
  margin: 0;
  word-break: break-all;
}

.manual-docs__code p span {
  display: block;
  padding-left: 15px;
}

.manual-docs__title {
  margin-bottom: 25px;
}

.manual-docs__title h5 {
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  color: #48a0f8;
}

.manual-docs__sdk p.medium,
.manual-docs__token p.medium {
  color: #1f3349;
}

.manual-docs__sdk {
  margin-bottom: 30px;
}

.manual-docs__sdk p.p2 {
  margin: 25px 0;
}

.manual-docs__token {
  margin-bottom: 30px;
}

.manual-docs__token p span {
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
  padding: 5px;
  color: #1f3349;
  margin: 0 5px;
}

.goong-terms__category {
  position: relative;
}

.goong-terms__content {
  padding: 70px 0;
}

.goong-terms__content p {
  color: #566571;
}

.goong-terms__group {
  padding: 30px 0;
  border-bottom: 1px solid #f2f2f2;
}

.goong-terms__group:last-child {
  border-bottom: none;
}

.goong-terms__group ul li {
  padding-bottom: 30px;
}

.goong-terms__group ul li:last-child {
  padding-bottom: 0;
}

.goong-terms__group ul li p.medium {
  font-size: 18px;
  line-height: 21px;
  color: #1f3349;
  margin: 0 0 20px;
}

.goong-terms__group ul li p {
  margin: 0 0 30px;
}

.goong-terms__group ul li p:last-child {
  margin: 0;
}

.goong-terms__title {
  margin: 0 0 20px;
}

.goong-terms__title h5 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin: 0 0 20px;
}

.goong-terms__title p {
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 30px;
}

.category__content {
  padding: 40px 0;
}

@media (min-width: 768px) {
  .category {
    display: flex;
    justify-content: flex-start;
    margin: 0 -15px;
  }

  .category .home-title {
    text-align: left;
  }

  .category__content {
    width: 50%;
    padding: 40px 15px;
  }
}

@media (min-width: 992px) {
  .category__content {
    padding: 80px 15px 0;
  }
}

.category__list {
  margin: 30px 0 0;
}

.category__list .medium {
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 15px;
}

.category__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #48a0f8;
  margin: 0;
}

.category__item {
  padding-bottom: 10px;
}

.category__item:last-child {
  padding: 0;
}

.category__item ul li {
  font-size: 16px;
  line-height: 26px;
  color: #48a0f8;
}

@media (min-width: 768px) {
  .category__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 50%;
    padding: 0 0 0 15px;
  }
}

.category__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 0 0 80px;
}

.goong-account {
  margin: 30px 0;
}

.goong-account__body {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .goong-account__body {
    margin: 0 -15px;
  }

  .goong-account__left {
    width: 66.667%;
    padding: 0 15px;
  }

  .goong-account__right {
    width: 33.333%;
    padding: 0 15px;
  }
}

@media (max-width: 1200px) {
  .goong-account__body {
    flex-direction: column-reverse;
  }

  .goong-account__body .goong-account__left {
    margin-bottom: 10px;
  }
}

.account-box {
  background-color: #fff;
  padding: 30px 24px 0px 20px;
  border-radius: 8px;
}

.account-box__header {
  padding: 0 0 15px;
  border-bottom: 1px solid #efefef;
  text-align: center;
}

.border_question {
  border-left: 1px solid #efefef;
  height: 30px;
  margin-left: 20px;
}

@media (min-width: 992px) {
  .account-box__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
  }

  .account-box__header .header_center {
    text-align: center;
  }
}

.account-box__header .small_billing {
  min-width: 140px;
}

@media (max-width: 460px) {
  .account-box__header .small_billing {
    line-height: 18px;
    padding: 8px 12px;
    font-size: 13px;
    border-radius: 6px;
    min-width: 105px;
  }
}

.account-box__header h5 {
  font-weight: 500;
  font-size: 28px;
  color: #1f3349;
}

@media (min-width: 1200px) {
  .account-box__header .title-dashboard {
    width: calc(100% - 340px);
  }
}

.account-box__header p {
  display: inline-block;
  margin: 15px 12px 0px 0px;
  background: #f9f9f9;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #1f3349;
  padding: 10px 15px;
  min-width: 150px;
}

.account-box__header .border_package {
  border-left: 1px solid #cacaca;
  height: 44px;
  margin: 15px 12px 0px 0px;
}

@media (min-width: 992px) {
  .account-box__header p {
    margin: 15px 12px 0px 0px;
  }
}

@media (max-width: 992px) {
  .account-box__header .border_package {
    border-top: 1px solid #efefef;
    width: 100%;
    border-left: none;
    height: 0px;
  }
}

.account-box__header p span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1f3349;
}

.account-box__progress__item .p1,
.account-box__title p {
  color: #566571;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.account-box__body {
  padding: 25px 0;
}

.account-box__body .account-box__item .account-box__title .total {
  /* font-weight: 500; */
}

.tbCl-bill-key p.typeAndCreate {
  min-width: 115px;
}

@media (min-width: 768px) {
  .account-box__body {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }

  .account-box__item {
    width: 50%;
    padding: 0 15px;
  }

  .keys-box__table .table-responsive {
    overflow-x: initial;
  }
}

.account-box__title {
  display: flex;
  align-items: flex-end;
}

.account-box__title p {
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  padding: 0 0 0px 15px;
}

.account-box__title span {
  margin: 0;
  padding: 0 0 10px 15px;
}

@media (max-width: 420px) {
  .account-box__title {
    justify-content: flex-start;
  }
}

@media (max-width: 620px) {
  .account-box__body .border_map_apis {
    border-top: 1px solid #efefef;
    width: 100%;
    margin: 25px 0px 25px 0px;
  }
}

.account-box__progress {
  padding: 13px 0;
}

.account-box__progress__item {
  padding: 12px 0;
}

.account-box__progress__item p {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}

.account-box__progress__item .p2 {
  color: #1f3349;
}

.account-box__progress__item .p2 span {
  font-weight: 500;
}

.account-box__footer {
  padding: 15px;
  text-align: center;
  border-top: 1px solid #efefef;
}

.account-box__footer a {
  font-size: 14px;
  line-height: 16px;
  color: #469af7;
}

.account-sidebar {
  background-color: #fff;
  padding: 30px 20px 0;
  border-radius: 8px;
}

.account-sidebar__header {
  padding: 0 0 15px;
  border-bottom: 1px solid #efefef;
}

.account-sidebar__header .d-flex {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.account-sidebar__header .d-block {
  width: 100%;
  text-align: center;
}

.account-sidebar__header h5 {
  font-weight: 500;
  font-size: 28px;
  /* line-height: 33px; */
  color: #1f3349;
  margin: 0 0 15px;
}

@media (min-width: 992px) {
  .account-sidebar__header .d-flex {
    justify-content: space-between;
  }

  .account-sidebar__header .d-block {
    width: auto;
  }

  .account-sidebar__header h5 {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .account-sidebar__header .d_margin_top {
    margin-top: 0px;
  }

  .account-sidebar__header .d_flex {
    display: block;
  }

  .account-sidebar__header .full_key {
    width: 100%;
  }
}

.account-sidebar .small_d_flex {
  margin-top: 12px;
  display: flex;
}

.account-sidebar .small_d_flex .full_coupon {
  width: 75%;
  margin-right: 12px;
  height: 45px;
}

.account-sidebar .small_d_flex .full_use {
  width: 25%;
  padding: 13px 13px;
}

@media (max-width: 992px) {
  .account-sidebar .small_d_flex {
    display: block;
  }

  .account-sidebar .small_d_flex .full_coupon {
    width: 100%;
    margin-right: 0px;
  }

  .account-sidebar .small_d_flex .full_use {
    width: 100%;
    padding: 13px 13px;
    margin-top: 22px;
  }

  .small_d_flex .p-explain_dashboard {
    margin-top: -66px;
  }
}

.account-sidebar__header p {
  font-size: 14px;
  line-height: 21px;
  color: #566571;
  margin: 25px 0 0;
}

.account-sidebar__header p a {
  color: #469af7;
  text-decoration: underline;
}

.account-sidebar__body {
  padding: 25px 0;
}

.account-sidebar__item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.account-sidebar__item .p1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1f3349;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.account-sidebar__item .p2 {
  font-size: 14px;
  line-height: 21px;
  color: #566571;
}

.account-sidebar__item .p2 span {
  font-weight: 500;
}

.keys-box {
  background: #fff;
  border-radius: 8px;
  padding: 30px 20px;
}

.keys-box__table table thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #566571;
}

.keys-box__table table tbody .td-name {
  width: 20%;
}

.keys-box__table table tbody .td-name p {
  font-size: 14px;
  line-height: 16px;
}

.keys-box__table table tbody .td-name .medium {
  font-size: 16px;
  line-height: 19px;
}

.keys-box__table table tbody .td-key {
  width: 35%;
}

.config-scroll-table .ant-table-content,
.config-scroll-table .ant-table-body {
  overflow: auto !important;
}

.table-config-tagP p.medium {
  font-weight: 500 !important;
  color: black;
}

.keys-box__table table tbody .td-desc {
  width: 100%;
}

.keys-box__table table tbody .td-desc p {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 4px;
  padding: 10px;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
}

.keys-box__table table tbody .td-url {
  width: 10%;
}

.keys-box__table table tbody .td-url p {
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}

.keys-box__table table tbody .td-edit {
  width: 5%;
}

.keys-box__table table tbody .td-edit .dropdown .dropdown-toggle {
  padding: 0;
}

.keys-box__table table tbody .td-edit .dropdown .dropdown-menu {
  width: 120px;
}

.keys-box__table table tbody .td-edit .dropdown .dropdown-link span {
  padding-left: 15px;
}

.goong-keys {
  margin: 30px 0;
}

@media (min-width: 992px) {
  .modal-dialog.modal-create-keys .modal-body {
    padding: 15px 30px;
  }
}

@media (min-width: 768px) {
  .modal-dialog.modal-create-keys {
    width: 650px;
  }

  .modal-dialog.modal-verify-code {
    width: 600px;
    margin: 130px auto 0;
  }
}

@media (min-width: 992px) {
  .modal-dialog.modal-verify-code .modal-body {
    padding: 15px 30px;
  }
}

.modal-dialog.modal-verify-code .modal-body .form-group {
  text-align: center;
}

.modal-dialog.modal-verify-code .modal-body .form-group:last-child {
  margin: 0;
}

.modal-dialog.modal-verify-code .modal-body .form-group .form-control {
  width: 100%;
}

.modal-dialog.modal-verify-code .modal-body .form-group p {
  text-align: left;
  margin: 0;
}

.modal-dialog.modal-verify-code .modal-body .form-group .btn {
  width: 130px;
  margin: auto;
}

.create-keys__item {
  margin-bottom: 30px;
}

.create-keys__item.create-keys__table {
  border-top: 1px solid #eee;
  margin: 0 -30px 30px;
  padding: 0 30px;
  position: relative;
}

.create-keys__item.create-keys__table .table {
  margin: 0;
  table-layout: fixed;
}

.create-keys__item.create-keys__table .table button {
  margin: 0 2px;
}

.create-keys__item.create-keys__table .table button svg {
  display: block;
}

.create-keys__item.create-keys__table .table .d-flex {
  justify-content: flex-end;
}

.create-keys__item.create-keys__table .table .d-flex .btn {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 18px;
  border-radius: 3px;
  margin: 0 2px;
}

.create-keys__item.create-keys__table .table .form-control {
  padding: 10px;
  width: 100%;
}

.create-keys__item.create-keys__table .table td,
.create-keys__item.create-keys__table .table th {
  vertical-align: middle;
  padding: 10px;
}

.create-keys__item.create-keys__table .btn-add {
  position: absolute;
  bottom: -62px;
  left: 30px;
  color: #3a93f9;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.create-keys__name .form-control {
  width: 100%;
  height: 44px;
}

.create-keys__name p {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  margin: 5px 0 0;
  color: rgba(31, 51, 73, 0.5);
}

.billing-list__header .heading,
.create-keys__btn {
  text-align: center;
}

.create-keys__desc .form-control {
  width: 100%;
  height: 100px;
}

.create-keys__title {
  margin: 0 0 15px;
}

.create-keys__title p {
  margin: 0;
}

.create-keys__title .p1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}

.create-keys__title .p2 {
  font-size: 14px;
  line-height: 16px;
  color: #566571;
}

@media (min-width: 768px) {
  .create-keys__radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.create-keys__radio .goong-tooltip {
  display: inline-block;
  padding-left: 5px;
  vertical-align: text-bottom;
}

.create-keys__radio .goong-tooltip__box {
  width: 280px;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
}

.create-keys__radio .goong-tooltip__box:before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: -7px;
  transform: rotate(45deg) translateY(-50%);
  box-shadow: -20px 0 14px rgba(0, 0, 0, 0.15);
}

.admin-wrapper__page,
/* .admin-wrapper__page--bill, */
.billing-detail,
.billing-list,
.billing-sidebar,
.goong-settings__body,
.goong-statis__body {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.02);
}

.create-keys__btn {
  height: 45px;
}

.create-keys__btn .btn {
  width: 250px;
  height: 45px;
}

.goong-billing {
  /* margin: 30px 0; */
  /* position: relative; */
}

@media (min-width: 768px) {
  .goong-billing__body {
    display: flex;
    margin: 0 -15px;
  }

  .goong-billing__left {
    width: 66.667%;
    padding: 0 15px;
  }

  .goong-billing__right {
    width: 33.333%;
    padding: 0 15px;
  }
}

.billing-list {
  background: #fff;
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 30px;
}

.billing-list__header {
  border-bottom: 1px solid #efefef;
  padding: 0 0 10px;
}

@media (min-width: 992px) {
  .billing-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 15px;
  }

  .billing-list__header .heading {
    text-align: left;
    margin: 0;
  }
}

.billing-list__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billing-list__viewall {
  padding-left: 40px;
}

.billing-list__viewall a {
  font-size: 14px;
  line-height: 16px;
  color: #469af7;
}

.billing-list__table {
  margin: 0 -15px;
}

.billing-list__table .table {
  margin: 0;
}

.billing-list__table .table thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #566571;
}

.billing-list__table .table tbody td p {
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #1f3349;
}

.billing-list__table .table tbody td p span,
.billing-list__table .table tbody td p svg {
  vertical-align: middle;
}

.billing-list__table .table tbody td p span {
  padding-left: 10px;
}

.billing-list__table .table tbody td p.p-close {
  color: #566571;
}

.billing-list__table .table tbody td a {
  font-size: 14px;
  line-height: 16px;
  color: #469af7;
}

.billing-sidebar {
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 8px;
}

.billing-sidebar__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #1f3349;
  margin: 0 0 15px;
}

.billing-sidebar__bank li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.billing-sidebar__bank li:last-child {
  padding-bottom: 0;
}

.billing-sidebar__bank li p {
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #1f3349;
}

.billing-sidebar__bank li p:first-child {
  width: 35%;
}

.billing-sidebar__bank li p:last-child {
  width: 65%;
  text-align: right;
  font-weight: 500;
}

.billing-detail {
  background: #fff;
  border-radius: 8px;
  padding: 30px 20px;
}

@media (min-width: 992px) {
  .billing-detail {
    padding: 30px 20px 0;
  }
}

@media (min-width: 1200px) {
  .billing-detail {
    width: 920px;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .billing-detail__body {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }

  .billing-detail__left {
    width: 70%;
    padding: 0 15px;
  }

  .billing-detail__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -20px;
  }
}

.billing-detail__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 15px;
}

.billing-detail__line .p1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #566571;
  margin: 0;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .billing-detail__line {
    width: 50%;
    display: block;
    padding: 0 20px 40px;
  }

  .billing-detail__line .p1 {
    margin: 0 0 15px;
  }
}

.billing-detail__line .p2 {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #1f3349;
  text-align: right;
  padding-left: 15px;
}

.billing-detail__line .p2 span {
  display: block;
}

.billing-detail__line .p2 .btn-add {
  font-size: 14px;
  line-height: 24px;
  color: #469af7;
}

@media (min-width: 992px) {
  .billing-detail__line .p2 {
    text-align: left;
    padding: 0;
  }

  .billing-detail__charges {
    width: 80%;
  }
}

.billing-detail__charges .p-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  color: #1f3349;
}

.billing-detail__charges .charges-comp__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px 0;
  border-bottom: 1px solid #efefef;
  margin: 0 -10px;
}

.billing-detail__charges .charges-comp__line p {
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #1f3349;
  padding: 0 10px;
}

.billing-detail__contact {
  padding: 15px 0;
}

.billing-detail__contact p {
  font-size: 14px;
  line-height: 24px;
  color: #566571;
}

.billing-detail__contact p a,
.billing-detail__contact p button {
  color: #469af7;
  font-size: 14px;
  vertical-align: baseline;
}

.admin-header__btn .btn span,
.admin-header__btn .btn svg,
.admin-navbar__link span,
.admin-table .table tbody td,
.admin-table .table thead th .th-sort,
.billing-detail__status span,
.billing-detail__status svg,
.connect-apps__btn .btn span,
.connect-apps__btn .btn svg,
.customer-detail__table .table-billing td p span,
.customer-detail__table .table-billing td p svg,
.edit-card__btn .d-block .btn span,
.edit-card__btn .d-block .btn svg,
.goong-statis__btn span,
.goong-statis__btn svg {
  vertical-align: middle;
}

@media (min-width: 992px) {
  .billing-detail__right {
    width: 30%;
    padding: 0 15px;
  }
}

.billing-detail__status {
  background: #e5f3ff;
  border: 1px solid #d9ebff;
  border-radius: 10px;
  padding: 12px 15px;
  text-align: center;
  margin-bottom: 40px;
}

.billing-detail__status span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #469af7;
  padding-left: 10px;
}

.billing-detail__price {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.billing-detail__price p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #1f3349;
}

.billing-detail__price p.p-price {
  font-size: 24px;
  line-height: 24px;
  margin: 15px 0 0;
}

.billing-detail__price a {
  font-size: 14px;
  line-height: 16px;
  color: #469af7;
  display: inline-block;
  margin-top: 15px;
}

.goong-settings {
  margin: 30px 0;
}

.goong-settings__body {
  background: #fff;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .goong-settings__main {
    display: flex;
    justify-content: space-between;
  }
}

.goong-settings__header {
  padding: 15px 20px;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goong-settings__header .heading {
  margin: 0;
}

@media (min-width: 992px) {
  .goong-settings__header {
    padding: 35px 20px 15px;
    display: block;
  }

  .goong-settings__left {
    width: 30%;
    border-right: 1px solid #efefef;
    padding: 15px 0 30px 20px;
  }
}

.goong-settings__right {
  padding: 30px 20px;
}

.goong-settings__item {
  display: none;
  animation: opacityShow 0.5s;
}

.goong-settings__item.active {
  display: block;
}

.navbar-settings {
  background-color: #f2f8ff;
}

@media (max-width: 991px) {
  .navbar-settings {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .navbar-settings.open {
    height: 244px;
  }
}

@media (min-width: 992px) {
  .goong-settings__right {
    width: 70%;
    padding: 30px 20px 30px 30px;
  }

  .navbar-settings {
    background-color: #fff;
  }

  .navbar-settings__toggle {
    display: none;
  }

  .navbar-settings__item.active {
    background-color: #f2f8ff;
    border-left: 4px solid #8cc8ff;
  }
}

.navbar-settings__toggle svg {
  display: block;
}

.navbar-settings__item.active .navbar-settings__link {
  color: #1f3349;
  font-weight: 500;
}

.navbar-settings__link {
  font-size: 16px;
  line-height: 19px;
  color: rgba(31, 51, 73, 0.5);
  padding: 15px;
  display: block;
}

@media (min-width: 992px) {
  .navbar-settings__link {
    padding: 15px 15px 15px 35px;
  }
}

.change-password .form-group {
  margin-bottom: 30px;
  margin-top: 20px;
}

.change-password .form-group label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
  margin-bottom: 10px;
  display: block;
}

.change-password .form-group .form-control {
  width: 100%;
}

.change-password__btn .btn {
  width: 100%;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .change-password__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .change-password__btn .btn {
    margin: 0;
  }
}

.profile-box__avatar {
  margin-bottom: 30px;
}

.profile-box__avatar .p-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
  margin: 0 0 10px;
}

.profile-box__avatar .change-avatar {
  display: flex;
  align-items: center;
}

.profile-box__avatar .change-avatar__img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-box__avatar .change-avatar__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-box__avatar .change-avatar__btn {
  padding-left: 15px;
}

.profile-box__avatar .change-avatar__btn .btn {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 7px 19px;
}

.profile-box__avatar .change-avatar__btn .btn input[type='file'] {
  display: none;
}

@media (min-width: 768px) {
  .profile-box__form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .profile-box__form .form-group {
    width: 50%;
    padding: 0 15px;
  }
}

.profile-box__form .form-group {
  margin-bottom: 30px;
}

.profile-box__btn .btn,
.profile-box__form .form-group .form-control {
  width: 100%;
}

.table-config-tagP p {
  margin-bottom: 0px;
}

.profile-box__form .form-group>label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-box__form .form-group>label a {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #469af7;
  text-align: right;
}

.security-box p {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: #566571;
}

.billing-box__title,
.security-box p.medium,
.security-box__form .form-group label {
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
}

.security-box p.medium {
  margin: 0 0 15px;
}

.security-box .button-switch {
  margin: 15px 0 30px;
}

.security-box__btn {
  margin: 40px 0 0;
}

@media (min-width: 768px) {
  .profile-box__btn {
    text-align: right;
    width: 100%;
    padding: 0 15px;
  }

  .profile-box__btn .btn {
    width: auto;
  }

  .security-box__btn {
    text-align: right;
  }
}

.security-box__btn .btn {
  width: 100%;
}

@media (min-width: 768px) {
  .security-box__btn .btn {
    width: auto;
  }
}

.security-box__form .form-group label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.security-box__form .form-group .form-control {
  width: 100%;
}

.security-box__qrcode {
  margin-bottom: 15px;
}

.security-box__qrcode__img {
  width: 100%;
  height: 180px;
}

@media (min-width: 768px) {
  .security-box__qrcode {
    display: flex;
  }

  .security-box__qrcode__img {
    width: 180px;
  }
}

.security-box__qrcode__img img {
  display: block;
  margin: 0px auto;
  height: 100%;
}

@media (max-width: 300px) {
  .security-box__qrcode__img {
    width: 100%;
    height: 120px;
  }

  .security-box__qrcode__img img {
    width: 120px;
    height: 120px;
  }

  .buttonSavePopup .btn {
    width: 95% !important;
  }
}

.security-box__qrcode__text {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .security-box__qrcode__text {
    width: calc(100% - 180px);
    padding-left: 30px;
    margin: 0;
  }
}

.security-box__qrcode__text .d-block {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 10px;
  padding: 15px;
}

.billing-box__title {
  font-weight: 500;
  margin: 0 0 15px;
}

.billing-box__overview {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .overview-flex {
    display: flex;
    margin: 0 -15px;
  }
}

.overview-flex__item {
  margin: 0 0 15px;
}

@media (min-width: 768px) {
  .overview-flex__item {
    padding: 0 15px;
    margin: 0;
    width: 100%;
  }

  .payment-flex {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
}

.payment-flex__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1f3349;
}

.payment-flex__item {
  padding: 0 0 15px;
}

@media (max-width: 607px) {
  .statisticPeruser {
    display: block !important;
  }

  .statisticPeruser .admin-datepicker {
    display: block;
  }

  .statisticPeruser .selectStatistic {
    margin-top: 10px;
    margin-left: 0px !important;
    height: 42px !important;
    padding: 0px 0px 0px 15px;
  }
}

@media (max-width: 389px) {
  .statisticPeruser .selectStatistic {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .payment-flex__item {
    width: 33.333%;
    padding: 0 15px;
  }
}

.payment-flex__item .p1 {
  font-size: 14px;
  line-height: 24px;
  color: #566571;
}

.payment-flex__item .p1 a {
  color: #469af7;
}

.edit-card {
  margin-top: 15px;
}

.edit-card__flex {
  display: flex;
  margin-bottom: 15px;
}

.edit-card__img {
  width: 45px;
  height: 35px;
  border-radius: 3px;
  overflow: hidden;
}

.edit-card__img img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.edit-card__text {
  width: calc(100% - 45px);
  padding-left: 10px;
}

.edit-card__text p {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  color: #566571;
}

.edit-card__text p.medium {
  font-size: 14px;
  color: #1f3349;
}

.edit-card__btn {
  display: flex;
  justify-content: space-between;
  margin: 0 -4px;
}

.edit-card__btn .d-block {
  width: 50%;
  padding: 0 4px;
}

.edit-card__btn .d-block .btn {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 8px;
}

.edit-card__btn .d-block .btn span {
  padding-left: 10px;
}

.add-card {
  margin-top: 15px;
}

.add-card__price {
  margin-bottom: 15px;
}

.add-card__price p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #566571;
}

.add-coupon,
.connect-apps__btn {
  margin-top: 15px;
}

.add-card__price p span {
  font-weight: 500;
  font-size: 24px;
  color: #1f3349;
}

@media (min-width: 768px) {
  .add-coupon .form-control {
    width: 100%;
    margin-bottom: 8px;
  }
}

.connect-apps__text {
  padding-bottom: 40px;
}

.connect-apps__preview {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.connect-apps__btn .btn {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 12px;
  border-radius: 4px;
}

.connect-apps__btn .btn span {
  padding-left: 10px;
}

.connect-apps p {
  font-size: 14px;
  line-height: 24px;
  color: #566571;
}

.connect-apps p.medium {
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 15px;
}

.goong-statis {
  padding: 30px 0;
}

.goong-statis__body {
  background: #fff;
  border-radius: 8px;
  padding: 15px;
}

@media (min-width: 768px) {
  .goong-statis__body {
    padding: 35px 20px;
  }
}

.goong-statis__line {
  border-bottom: 1px solid #eee;
  margin: 15px 0;
}

.goong-statis__btn {
  font-size: 14px;
  line-height: 16px;
  color: #469af7;
  background: rgba(244, 246, 251, 0.8);
  display: block;
  padding: 0 10px 0 0;
  margin-bottom: -5px;
}

.goong-statis__btn.expand svg {
  transform: rotate(90deg);
}

.goong-statis__btn span {
  padding-left: 10px;
}

.goong-statis__item {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.app-box__bg img,
.faq-box__bg img {
  border-radius: 0 0 0 80px;
  object-fit: cover;
}

@media (min-width: 500px) {
  .goong-statis__list {
    padding: 30px 0 30px 30px;
  }
}

.goong-statis__list .goong-chart {
  padding-bottom: 50px;
}

.goong-statis__list .goong-chart:last-child {
  padding: 0;
}

.goong-statis .heading {
  padding-bottom: 15px;
  border-bottom: 1px solid #efefef;
}

.goong-chart__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #1f3349;
  margin-bottom: 25px;
}

.goong-chart__text p.p1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #566571;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goong-chart__text p.medium {
  font-size: 24px;
  line-height: 24px;
  color: #1f3349;
}

.goong-chart__text p.p2 {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #1f3349;
}

.goong-app__intro {
  position: relative;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .goong-chart {
    display: flex;
    justify-content: space-between;
  }

  .goong-chart__text {
    width: 150px;
  }

  .goong-chart__content {
    width: calc(100% - 150px);
    padding-left: 30px;
  }

  .goong-pie_chart__content {
    width: calc(100% - 00px);
    padding-left: 30px;
  }

  .goong-app__intro {
    margin-bottom: 100px;
  }
}

.goong-app__traveling {
  position: relative;
  margin-bottom: 50px;
}

.goong-app__traveling .app-box {
  padding: 0;
}

@media (min-width: 768px) {
  .goong-app__traveling {
    margin-bottom: 100px;
    min-height: 440px;
  }

  .goong-app__traveling .app-box {
    justify-content: flex-end;
  }

  .goong-app__traveling .app-box__content {
    padding: 75px 15px 0;
  }
}

.goong-app__traveling .app-box__bg {
  right: auto;
  left: 0;
  padding: 0 15px 0 0;
}

.goong-app__traffic {
  position: relative;
  margin-bottom: 50px;
}

.goong-app__traffic .app-box {
  padding: 0;
}

@media (min-width: 768px) {
  .goong-app__traffic {
    margin-bottom: 100px;
    min-height: 440px;
  }

  .goong-app__traffic .app-box__content {
    padding: 75px 15px 0;
  }
}

.goong-app__team {
  margin-bottom: 50px;
}

.goong-app__download {
  padding: 50px 0;
}

@media (min-width: 768px) {
  .goong-app__team {
    margin-bottom: 100px;
  }

  .goong-app__download {
    padding: 100px 0;
  }
}

.goong-app .goong-home__functions {
  margin: 0;
}

.goong-app .goong-home__functions .functions-box p {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .app-box {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
}

.app-box__title {
  font-weight: 500;
  font-size: 38px;
  line-height: 44px;
  color: #1f3349;
  margin: 0 0 20px;
}

.app-box__text .p1 {
  font-size: 18px;
  line-height: 30px;
  color: #1f3349;
  margin: 0;
}

.app-box__btn {
  width: auto;
}

.app-box__btn .d-block {
  padding: 10px 0;
}

@media (min-width: 992px) {
  .app-box__btn {
    display: flex;
    justify-content: space-between;
    margin: 48px -15px 0;
  }

  .app-box__btn .d-block {
    width: 50%;
    padding: 0 15px;
  }
}

.app-box__btn .btn {
  width: 100%;
  font-size: initial;
  line-height: initial;
  padding: 19px 10px;
}

.app-box__btn .btn img,
.app-box__btn .btn span,
.app-box__btn .btn svg {
  vertical-align: middle;
}

.app-box__btn .btn img {
  width: 30px;
}

.app-box__btn .btn span {
  padding-left: 15px;
}

@media (min-width: 768px) {
  .app-box__content {
    width: 50%;
    padding: 40px 15px 0;
  }
}

@media (min-width: 992px) {
  .app-box__content {
    padding: 60px 15px;
  }
}

@media (min-width: 768px) {
  .app-box__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 50%;
    padding: 0 0 0 15px;
  }

  .team-box {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
}

.app-box__bg img {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .team-box__col {
    width: 50%;
    padding: 0 15px;
  }
}

.team-box__item {
  padding: 0 0 30px;
}

.team-box__img {
  width: 100%;
  height: 275px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .team-box__item {
    padding: 0;
  }

  .team-box__img {
    margin-bottom: 50px;
    height: 350px;
  }

  .team-box__title p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 992px) {
  .team-box__img {
    height: 400px;
  }
}

.team-box__img img {
  width: 100%;
  height: 100%;
}

.team-box__title {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .team-box__title {
    margin-bottom: 30px;
  }
}

.team-box__title p {
  font-weight: 500;
  font-size: 27px;
  line-height: 35px;
  margin: 0;
  color: #1f3349;
}

@media (min-width: 992px) {
  .team-box__title p {
    font-size: 38px;
    line-height: 45px;
  }
}

.team-box__text p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  color: #1f3349;
}

@media (min-width: 992px) {
  .team-box__text p {
    font-size: 18px;
    line-height: 30px;
  }
}

.download-box__text {
  text-align: center;
}

@media (min-width: 768px) {
  .download-box {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }

  .download-box__text {
    width: 50%;
    padding: 0 15px;
    text-align: left;
  }

  .download-box__btn {
    width: 50%;
    padding: 0 15px;
  }
}

.download-box__text p {
  font-size: 22px;
  line-height: 38px;
  color: #566571;
  margin: 0;
}

.download-box__text p.p1 {
  font-weight: 500;
  color: #48a0f8;
}

@media (min-width: 768px) {
  .download-box__btn .app-box__btn {
    margin: 0 -15px;
  }
}

.goong-faq__top {
  position: relative;
  margin-bottom: 40px;
}

.goong-faq__result li {
  padding-bottom: 50px;
}

.goong-faq__result li p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  color: #566571;
}

.goong-faq__result li p.medium {
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 20px;
  color: #1f3349;
}

.faq-box__title {
  margin-bottom: 20px;
}

.faq-box__title h5 {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  color: #1f3349;
}

.faq-box__list li button {
  font-size: 16px;
  line-height: 30px;
  color: #48a0f8;
  text-align: left;
}

.faq-box__content {
  padding: 40px 0;
}

@media (min-width: 768px) {
  .faq-box__content {
    width: 50%;
    padding: 80px 15px 20px 0;
  }
}

.faq-box__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  padding: 0 0 0 15px;
}

.faq-box__bg img {
  width: 100%;
  height: 100%;
}

.goong-price {
  margin: 50px 0;
}

.goong-price__title {
  margin-bottom: 30px;
}

.goong-price__title h2 {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  color: #1f3349;
}

.goong-price__table .table,
.goong-price__table .table p {
  margin: 0;
}

.goong-price__table .table th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
}

.goong-price__table .table td {
  font-size: 14px;
  line-height: 16px;
  color: #566571;
  background-color: #fff;
}

.goong-price__table .table .d-flex {
  justify-content: space-between;
  align-items: center;
}

.goong-price__table .table .d-flex p.p1 {
  color: #469af7;
  font-weight: 500;
  padding-right: 10px;
}

.goong-price__table .table .d-flex p.p2 {
  padding-left: 10px;
}

.admin-wrapper {
  display: flex;
  margin-left: 242px;
  width: calc(100% - 242px);
}

.admin-wrapper-setWidth {
  display: flex;
  margin-left: 50px;
  width: calc(100% - 50px);
}

.admin-wrapper__body {
  padding: 0px 12px 12px 12px;
  width: 100%;
}

.admin-wrapper__content {
  min-height: 100%;
}

.admin-wrapper__page {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
}

.admin-wrapper__page--bill {
  /* background: #000; */
  /* border-radius: 8px; */
  /* padding: 16px; */
}

.admin-header {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  align-items: center;
  margin-bottom: 30px;
}

.statistic-per-user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}

@media (max-width: 415px) {
  .statistic-per-user {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
  }
}

.statistic-per-user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.admin-header__title h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #1f3349;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.admin-header__title .p-info-item {
  margin-bottom: 12px;
}

.admin-header__btn .btn span {
  padding-left: 10px;
}

.search-group {
  display: flex;
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
}

.search-group__input {
  position: relative;
  padding: 10px 0;
  width: calc(100% - 25px);
}

.search-group__input input {
  background: 0 0;
  border: none;
  padding: 5px 15px 5px 40px;
  font-size: 14px;
  line-height: 16px;
  /* border-right: 1px solid #e4e8f3; */
  width: 100%;
}

.search-group__input input:focus {
  outline: 0;
}

.search-group__input svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.search-group__btn {
  padding: 0 15px;
  width: 50px;
}

.search-group__btn svg {
  display: block;
}

.admin-table {
  max-width: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.admin-table::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.admin-table::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 16px;
}

.admin-table::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 16px;
}

.admin-table::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}

.admin-table .table {
  margin: 0;
}

.admin-table .table thead th {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #566571;
  cursor: pointer;
}

.admin-table .table thead th .th-sort {
  display: inline-block;
  margin-left: 10px;
}

.admin-table .table thead th .th-sort__arrow {
  display: flex;
  flex-direction: column;
}

.admin-table .table thead th .th-sort__arrow svg {
  fill: rgba(31, 51, 73, 0.5);
}

.admin-table .table thead th .th-sort__arrow svg.active {
  fill: #1f3349;
}

.admin-table .table tbody td p {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
}

.admin-table .table tbody td>a {
  font-size: 14px;
  line-height: 16px;
  color: #469af7;
}

.admin-table .dropdown-toggle {
  padding: 0;
}

.admin-table .dropdown-menu {
  width: 150px;
  left: auto !important;
  right: -5px;
}

.admin-table__avatar {
  width: 44px;
  height: 44px;
  margin-left: 12px;
  border-radius: 50%;
}

.admin-table__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.admin-pagination {
  padding: 30px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-pagination .pagination {
  justify-content: flex-end;
}

.admin-datepicker {
  display: flex;
}

.admin-datepicker__date {
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 320px;
}

.admin-datepicker__date {
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 320px;
}

.admin-monthpicker {
  background: rgba(244, 246, 251, 0.8);
  border: 1px solid #f0f2f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 220px;
}

.admin-datepicker__in,
.admin-datepicker__out {
  position: relative;
  width: 50%;
}

.admin-datepicker__in input,
.admin-datepicker__out input {
  background: 0 0;
  border: none;
  padding: 9px 15px 9px 35px;
  width: 100%;
  display: block;
}

.admin-datepicker__in input:focus,
.admin-datepicker__out input:focus {
  outline: 0;
}

.admin-datepicker__in svg,
.admin-datepicker__out svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.admin-datepicker__btn {
  padding-left: 15px;
}

.admin-datepicker__btn .btn {
  width: 80px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.table-length {
  display: flex;
  align-items: center;
}

.table-length span {
  font-size: 14px;
  line-height: 19px;
}

.table-length .form-control {
  background: #fff;
  margin: 0 10px;
  padding: 7px 10px;
}

.admin-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 710px) {
  .admin-box__text {
    display: flex;
  }

  .admin-billing .admin-box .admin-box__text p strong {
    vertical-align: baseline;
    margin-left: 4px;
  }
}

@media (max-width: 710px) {
  .admin-box__text {
    width: 250px;
  }
}

.admin-box__text p {
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  margin-left: 16px;
}

.admin-box__select {
  display: flex;
}

/* .admin-box__text p:last-child {
  margin: 0;
} */

.admin-box__filter {
  /* margin: 0 -10px; */
  display: flex;
  align-items: center;
}

.admin-box__filter .form-group {
  width: 200px;
  padding: 0 10px;
  margin: 0;
}

.admin-box__filter .form-group .form-control {
  width: 100%;
}

.table-child td,
.table-child th {
  padding: 3px 5px !important;
  font-size: 13px;
  line-height: 18px;
  border-bottom: none !important;
  width: 33.333%;
  text-align: center;
  max-width: 70px;
}

.admin-navbar__link,
.sidebar__account p {
  font-size: 16px;
  line-height: 19px;
  display: flex;
}

.sidebar-customer-mobile .ant-menu-inline-collapsed {
  width: 50px !important;
}

.sidebar-customer-mobile .ant-layout-sider-trigger {
  border-top: 1px solid #efefef !important;
  border-right: 1px solid #efefef !important;
}

@media (min-width: 768px) {
  .modal-dialog.modal-create-keys.modal-admin {
    width: 750px;
  }
}

.sidebar-customer-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8 !important;
  background-color: #fff;
}

.sidebar-customer-content {
  height: 100vh;
}

.sidebar-admin-mobile .ant-layout-sider-trigger {
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}

.sidebar-admin-mobile {
  position: fixed;
  z-index: 8;
}

.icon-goong-sb-am {
  width: 30px;
  margin: 10px;
}

.icon-goong-2x {
  width: 130px !important;
  margin: 20px !important;
  margin-bottom: 0px !important;
}

.sidebar-admin-mobile .collapsedIcon button {
  width: 100%;
}

.sidebar-admin-mobile .logout-sidebar .shortNameUserSideBar {
  margin-left: 10px;
}

.avatar-sidebar-customer {
  width: 40px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.avatar-sidebar-collapsed {
  width: 30px;
  margin-left: 10px;
}

.sidebar-customer-mobile .sidebar-customer-content {
  overflow: auto !important;
  height: calc(100vh - 50px);
}

#sb-content::-webkit-scrollbar {
  display: none !important;
}

.shortNameUserSideBarCustomer {
  margin-left: 10px;
}

.avatar-sidebar-admin {
  margin-left: 20px;
  margin-bottom: 10px;
  height: 40px;
}

.avatar-sidebar-collapsed-admin {
  width: 30px;
  margin: 10px;
}

.avatar-admin-sb {
  color: #898989 !important;
}

.sidebar-admin-mobile .sidebar-admin-content {
  height: calc(100vh - 45px) !important;
  overflow: auto;
  width: 230px;
}

.sidebar-admin-mobile .button-collapsed-sidebar-admin {
  height: 30px;
}

.sidebar-admin-mobile .ant-menu-inline-collapsed {
  width: 50px;
}

.sidebar-admin-mobile .ant-menu-inline-collapsed>.ant-menu-item,
.sidebar-customer-mobile .ant-menu-inline-collapsed>.ant-menu-item,
.sidebar-admin-mobile .ant-menu-submenu-title {
  padding: 0 17px !important;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .admin-overview__statis-resize {
    display: block;
  }

  .admin-overview__statis-resize .statis-col {
    width: 100% !important;
    margin-bottom: 30px;
    padding: 0px !important;
  }
}

@media (max-width: 900px) {
  .admin-overview__chart__img {
    max-height: calc(50vh - 140px) !important;
  }
}

@media (min-width: 768px) and (max-height: 730px) {

  .admin-overview__statis,
  .admin-overview__statis-display {
    min-height: 250px !important;
  }
}

@media (max-width: 760px) {
  .admin-overview .statis-item__line {
    padding-bottom: 10px !important;
  }

  .admin-overview .statis-item {
    padding: 15px !important;
  }

  .admin-overview__chart {
    margin-top: 30px !important;
  }

  .admin-overview__title h2 {
    font-weight: bold !important;
  }

  .admin-overview__title {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 650px) {
  .admin-overview__chart .admin-overview__title {
    display: block !important;
    margin-bottom: 15px !important;
  }

  .goong-wrapper .faq-customer-box .container {
    margin-left: 0px !important;
    min-width: calc(100vw - 70px) !important;
  }
}

@media (max-width: 900px) {
  .admin-overview__statis {
    display: block !important;
  }

  .admin-overview__statis .statis-col {
    margin-bottom: 30px;
  }

  .admin-overview .statis-col {
    width: 100% !important;
  }
}

@media (max-width: 528px) {
  .admin-overview__chart .admin-overview__title .admin-datepicker {
    padding-left: 0px !important;
  }

  .admin-datepicker {
    display: block !important;
  }

  .admin-header-usageStatitistic .admin-datepicker .admin-datepicker__date {
    width: calc(100vw - 150px) !important;
  }

  .admin-datepicker__btn {
    margin-top: 10px;
    text-align: center;
  }

  .customer-statistic .admin-datepicker__date {
    width: calc(100vw - 110px);
  }

  .admin-overview__chart .admin-datepicker__date {
    width: 100% !important;
  }

  .admin-header-usageStatitistic .selectStatistic {
    width: calc(100vw - 150px);
  }
}

@media (max-width: 430px) {
  .admin-wrapper__body {
    padding: 10px !important;
  }

  .showEditInfoAdmin {
    width: calc(100vw - 110px) !important;
  }

  .form-group.profile-user-customer .phoneUserProfile {
    display: block !important;
    text-align: center;
  }

  .form-group.profile-user-customer .phoneUserProfile .btn-blue {
    margin-top: 10px;
  }

  .admin-header-usageStatitistic .selectStatistic {
    width: calc(100vw - 110px);
  }

  .admin-header-usageStatitistic .admin-datepicker .admin-datepicker__date {
    width: calc(100vw - 110px) !important;
  }

  .admin-overview__chart {
    padding: 20px !important;
  }
}

@media (max-width: 400px) {
  .admin-datepicker__date {
    display: block !important;
  }

  .admin-datepicker__in input,
  .admin-datepicker__out input {
    padding: 9px 0px 9px 35px !important;
    width: 50vw;
  }

  .admin-header-usageStatitistic .admin-datepicker .admin-datepicker__date {
    width: calc(100vw - 110px) !important;
  }

  .admin-header-usageStatitistic .selectStatistic,
  .admin-header-usageStatitistic .admin-datepicker__date {
    width: calc(100vw - 110px) !important;
  }

  .admin-overview__chart .admin-datepicker__date {
    width: calc(100vw - 110px) !important;
  }

  .showRQsupport .timer.dpf {
    display: none;
  }

  .showRQsupport .ans {
    padding-bottom: 25px;
  }

  .showRQsupport .ans .badgeShow {
    position: relative !important;
    margin-top: 25px !important;
  }
}

.sidebar__body {
  height: calc(100% - 91px);
}

li.ant-menu-item.sidebar-admin-logo.ant-menu-item-only-child {
  height: 60px !important;
  line-height: 60px;
  padding-left: 10px !important;
}

.sidebar__logo h1 a {
  display: block;
}

.sidebar__logo h1 a img {
  width: 127px;
}

.sidebar__navbar {
  height: calc(100% - 70px);
}

.sidebar__account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 40px;
  border-top: 1px solid #efefef;
}

.sidebar__account p {
  margin: 0;
  font-weight: 500;
  color: #1f3349;
  align-items: center;
  padding-right: 10px;
}

.sidebar__account p img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.sidebar__account p span {
  padding-left: 10px;
  width: calc(100% - 40px);
  word-break: break-all;
}

.admin-navbar__item.active .admin-navbar__link {
  background-color: #f2f8ff;
  border-left: 4px solid #8cc8ff;
  color: #3a93f9 !important;
}

.admin-navbar__link {
  color: rgba(86, 101, 113, 0.5);
  padding: 15px 20px 15px 40px;
  transition: all 0.2s;
}

.admin-navbar__link:hover {
  color: #3a93f9;
}

.admin-navbar__link .span-svg {
  width: 25px;
}

.admin-navbar__link span {
  display: inline-block;
}

.admin-navbar__link .span-svg svg {
  display: block;
}

.admin-navbar__link .span-text {
  padding-left: 10px;
  width: calc(100% - 25px);
}

.copy-keys {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.copy-keys__code {
  padding-right: 15px;
  overflow: hidden;
  width: 100%;
}

.copy-keys__code p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 25px;
  margin: 0;
  word-break: break-all;
}

.copy-keys__btn {
  width: 24px;
}

.copy-keys__btn button svg {
  display: block;
}

.customer-detail__table .admin-table {
  padding-top: 15px;
}

.customer-detail__table .admin-table .table {
  display: none;
}

.customer-detail__table .admin-table .table.active {
  display: table;
}

.customer-detail__table .table-billing {
  margin: 0;
  table-layout: fixed;
}

.customer-detail__table .table-billing thead tr {
  display: flex;
}

.customer-detail__table .table-billing tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.customer-detail__table .table-billing tbody tr,
.customer-detail__table .table-keys thead tr {
  display: flex;
}

.customer-detail__table .table-billing tbody::-webkit-scrollbar {
  width: 6px;
}

.customer-detail__table .table-billing td,
.customer-detail__table .table-billing th {
  width: 25%;
}

.customer-detail__table .table-billing tbody::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 16px;
}

.customer-detail__table .table-billing tbody::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 16px;
}

.customer-detail__table .table-billing tbody::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}

.customer-detail__table .table-billing td p.p-close {
  color: #566571;
}

.customer-detail__table .table-billing td p span {
  padding-left: 10px;
}

.customer-detail__table .table-billing td:nth-child(2),
.customer-detail__table .table-billing th:nth-child(2) {
  width: 40%;
}

.customer-detail__table .table-billing td:nth-child(4),
.customer-detail__table .table-billing th:nth-child(4),
.customer-detail__table .table-keys thead tr th:nth-child(1) {
  width: 20%;
}

.customer-detail__table .table-keys {
  margin: 0;
  table-layout: fixed;
}

.customer-detail__table .table-keys thead tr th:nth-child(2) {
  width: 35%;
}

.customer-detail__table .table-keys thead tr th:nth-child(3) {
  width: 30%;
}

.customer-detail__table .table-keys thead tr th:nth-child(4) {
  width: 10%;
}

.customer-detail__table .table-keys thead tr th:nth-child(5) {
  width: 5%;
}

.customer-detail__table .table-keys tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.customer-detail__table .table-keys tbody::-webkit-scrollbar {
  width: 6px;
}

.customer-detail__table .table-keys tbody::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 16px;
}

.customer-detail__table .table-keys tbody::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 16px;
}

.customer-detail__table .table-keys tbody::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}

.customer-detail__table .table-keys tbody tr {
  display: flex;
}

.customer-detail__table .table-keys tbody .td-name {
  width: 20%;
}

.customer-detail__table .table-keys tbody .td-name p {
  font-size: 14px;
  line-height: 16px;
}

.customer-detail__table .table-keys tbody .td-name .medium {
  font-size: 16px;
  line-height: 19px;
}

.customer-detail__table .table-keys tbody .td-key {
  width: 35%;
}

.customer-detail__table .table-keys tbody .td-key .copy-keys {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.customer-detail__table .table-keys tbody .td-key .copy-keys__code {
  padding-right: 15px;
}

.customer-detail__table .table-keys tbody .td-key .copy-keys__code p {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  word-break: break-all;
}

.customer-detail__table .table-keys tbody .td-key .copy-keys__btn {
  width: 24px;
}

.customer-detail__table .table-keys tbody .td-key .copy-keys__btn button svg {
  display: block;
}

.customer-detail__table .table-keys tbody .td-desc {
  width: 30%;
}

.customer-detail__table .table-keys tbody .td-desc p {
  background: rgba(244, 246, 251, 0.8);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 20px;
}

.customer-detail__table .table-keys tbody .td-url {
  width: 10%;
}

.customer-detail__table .table-keys tbody .td-edit {
  width: 5%;
}

.customer-detail__table .table-keys tbody .td-edit .dropdown .dropdown-toggle {
  padding: 0;
}

.customer-detail__table .table-keys tbody .td-edit .dropdown .dropdown-menu {
  width: 120px;
}

@media (min-width: 768px) {
  .customer-detail__table .table-keys tbody .td-edit .dropdown .dropdown-menu {
    left: auto;
    right: 10px;
    top: calc(100% + 10px) !important;
  }
}

.customer-detail__table .table-keys tbody .td-edit .dropdown .dropdown-menu:before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 1px;
  position: absolute;
  top: -4px;
  left: 93px;
  transform: rotate(45deg);
  box-shadow: -7px -12px 14px rgba(0, 0, 0, 0.15);
}

.customer-detail__table .table-keys tbody .td-edit .dropdown .dropdown-link span {
  padding-left: 15px;
}

.customer-detail__tab {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}

.customer-detail__tab li {
  padding: 0 10px;
}

.customer-detail__tab li.active button {
  color: #1f3349;
}

.customer-detail__tab li button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(86, 101, 113, 0.5);
}

.customer-detail .profile-box {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
}

.customer-detail .profile-box__avatar {
  width: 175px;
  padding: 0 15px;
  margin: 0;
}

.customer-detail .profile-box__avatar .change-avatar {
  display: block;
}

.customer-detail .profile-box__avatar .change-avatar__img {
  margin: auto;
}

.customer-detail .profile-box__avatar .change-avatar__btn {
  margin-top: 18px;
  padding: 0;
}

.customer-detail .profile-box__avatar .change-avatar__btn .btn-upload {
  width: 100%;
}

.customer-detail .profile-box__avatar .change-avatar__btn .btn-upload .btn {
  padding: 7px 0;
  width: 100%;
}

.customer-detail .profile-box__form {
  width: calc(100% - 160px);
  padding: 0 15px;
  margin: 0;
  display: block;
}

.customer-detail .profile-box__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.customer-detail .profile-box__flex .form-group {
  width: 33.333%;
}

.customer-detail .profile-box__flex .form-group>label {
  display: block;
}

.customer-detail .profile-box__flex .form-group[data-tip]:before {
  left: 25px;
}

.customer-detail .profile-box__flex .form-group[data-tip]:after {
  left: 15px;
}

.admin-billing .admin-header__title {
  width: 300px;
}

@media (max-width: 940px) {
  .admin-billing .admin-box {
    display: block;
  }

  .admin-billing .admin-box__filter {
    margin-bottom: 20px;
  }
}

@media (max-width: 900px) {
  .admin-billing .admin-box {
    display: flex;
  }

  .admin-billing .admin-box__filter {
    margin-bottom: 0px;
  }

  .tableBillAndkeys .table-responsive .table-billing {
    min-width: 600px;
  }

  .tableBillAndkeys .table-responsive .table-keys {
    min-width: 800px;
  }

  .tableBillAndkeys {
    overflow-x: auto;
  }

  .tableBillAndkeys .admin-pagination {
    margin-bottom: 35px;
  }

  .tableBillAndkeys .admin-pagination .pagination {
    position: fixed;
    right: 40px;
  }

  .tableBillAndkeys .admin-pagination .table-length {
    position: fixed;
  }
}

@media (max-width: 600px) {
  .table-length {
    display: none !important;
  }

  .admin-pagination .pagination {
    margin: 0px auto;
  }
}

@media (max-width: 435px) {
  .tableBillAndkeys .admin-pagination .pagination {
    position: fixed;
    margin-top: 40px;
    right: 20px;
  }

  .tableBillAndkeys .admin-pagination {
    margin-bottom: 70px;
  }

  .goong-settings__item.security-box.active p {
    text-align: justify;
  }
}

@media (max-width: 383px) {
  .tableBillAndkeys .admin-pagination {
    margin-bottom: 80px;
  }

  .registerFactorApp {
    white-space: pre-line;
  }
}

@media (max-width: 710px) {
  .admin-billing .admin-box {
    display: block;
  }

  .admin-billing .admin-box__filter {
    margin-bottom: 20px;
  }
}

.admin-billing .admin-header__search {
  /* width: calc(100% - 200px); */
  flex-grow: 1;
}

.admin-usage .goong-statis {
  padding: 0;
}

.admin-usage .goong-statis__item:last-child {
  margin: 0;
}

.admin-sale .admin-header__btn {
  width: 150px;
}

.admin-sale .admin-header__btn .btn {
  width: 100%;
}

.admin-sale .admin-table .table tbody td:last-child {
  width: 350px;
}

.list-btn {
  display: flex;
  margin: 0 -5px;
}

.list-btn li {
  width: 33.333%;
  padding: 0 5px;
}

.list-btn li .btn {
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.list-btn li .btn.btn-delete {
  background: rgba(247, 70, 70, 0.08);
  border: 1px solid #ff7171;
  color: #ff2f2f;
}

.list-btn li .btn.btn-edit {
  background: rgba(70, 154, 247, 0.08);
  border: 1px solid #469af7;
  color: #469af7;
}

.list-btn li .btn.btn-suspend {
  background: rgba(193, 211, 224, 0.198372);
  border: 1px solid #c1d3e0;
  color: #74838f;
}

.admin-overview {
  background-color: #f4f6fb;
  margin: -20px;
}

.admin-overview .statis-item,
.admin-overview__chart {
  background: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.admin-overview__title {
  margin-bottom: 30px;
}

.admin-overview__title h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #1f3349;
}

@media screen and (min-width: 628px) {
  .admin-overview__statis {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
}

@media (max-width: 627px) {
  .admin-overview .statis-item {
    padding: 20px !important;
  }

  .admin-overview .statis-col {
    padding: 0px !important;
  }
}

@media (max-width: 850px) and (max-height: 755px) {

  .admin-overview__statis .statis-col,
  .admin-overview__statis .statis-item {
    min-height: 300px !important;
  }

  .admin-overview__statis .statis-col,
  .admin-overview__statis .statis-item__content {
    min-height: 230px !important;
  }
}

.admin-overview__chart {
  padding: 40px 20px;
  margin-top: 30px;
}

.admin-overview__chart .admin-overview__title {
  display: flex;
  align-items: center;
}

.admin-overview__chart .admin-overview__title .admin-datepicker {
  padding-left: 30px;
}

@media (min-width: 900px) and (min-height: 731px) {
  .admin-overview .statis-col {
    height: calc(50vh - 106px) !important;
  }
}

@media (min-width: 900px) and (max-height: 690px) {
  .admin-overview .statis-item__content {
    /* min-height: 140px !important; */
    height: auto;
  }

  .admin-overview__chart__img canvas {
    min-height: 170px !important;
  }

  .admin-overview__chart {
    min-height: 300px !important;
  }
}

@media screen and (min-width: 901px) {
  .admin-overview__chart__img {
    max-height: calc(50vh - 140px) !important;
  }
}

.admin-overview .statis-col {
  width: 33.333%;
  padding: 0 15px;
}

.admin-overview .statis-item {
  padding: 20px 20px;
}

@media (max-width: 800px) {
  .modal-dialog {
    margin: 10px auto !important;
  }
}

@media (min-width: 768px) {
  .admin-overview .statis-item {
    height: 100%;
  }
}

.admin-overview .statis-item__content img {
  width: 100%;
}

.admin-overview .statis-item__line {
  padding-bottom: 30px;
}

.admin-overview .statis-item__line:last-child {
  padding: 0;
}

.admin-overview .statis-item__line p {
  margin: 0;
}

.admin-overview .statis-item__line p.p1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1f3349;
}

.admin-overview .statis-item__line p.p2 {
  font-size: 40px;
  line-height: 47px;
}

.admin-setting__item,
.admin-setting__title {
  margin-bottom: 15px;
}

.admin-setting__title h5 {
  font-size: 16px;
  line-height: 19px;
  color: #1f3349;
}

.admin-setting__form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.admin-setting__form .form-group {
  width: 50%;
  padding: 0 15px;
}

.admin-setting__form .form-control {
  width: 100%;
}

.admin-setting__btn {
  text-align: center;
}

.admin-setting__btn .btn {
  width: 125px;
}

.form-control.input-sm {
  height: 35px;
  padding: 5px 10px;
}

.btn-sm {
  height: 35px;
  padding: 5px 10px;
}

.admin-header .searchSaleAcc {
  width: 50%;
}

.searchSaleAcc .search-group__input input {
  border-right: none;
}

@media (max-width: 1190px) {
  .admin-header .searchSaleAcc {
    width: calc(100vw - 530px);
  }
}

@media (max-width: 900px) {
  .admin-header .searchSaleAcc {
    width: calc(100vw - 310px);
  }
}

@media (max-width: 500px) {
  .flex-box-saleAcc {
    display: flex;
    flex-direction: column-reverse;
  }

  .admin-header .searchSaleAcc {
    width: calc(100vw - 150px);
    margin-top: 20px;
  }

  .headerSaleAcc .admin-header__btn {
    margin: 10px auto;
  }

  .statis-item__content {
    min-height: calc(50vh - 150px);
  }

  .admin-box__text p {
    width: calc(100vw - 150px);
  }

  .addAndUpload {
    display: block !important;
  }

  .formAddQuestion {
    width: 100% !important;
    margin: 0px !important;
  }
}

@media (min-width: 501px) {
  .headerSaleAcc .btnlistsaleAcc {
    display: none;
  }

  .headerSaleAcc .admin-header__btn {
    display: block;
  }
}

@media (max-width: 500px) {
  .headerSaleAcc .btnlistsaleAcc {
    display: block;
    margin: 10px auto;
  }

  .headerSaleAcc .admin-header__btn {
    display: none;
  }
}

@media (max-width: 450px) {
  .admin-billing .admin-box .admin-box__text {
    width: 100%;
  }

  .admin-billing .admin-box .admin-box__text p {
    display: block;
    line-height: 25px;
  }

  .admin-billing .admin-box .admin-box__text p strong {
    vertical-align: baseline;
    margin-left: 10px;
  }
}

@media (max-width: 430px) {
  .admin-wrapper__page .admin-billing .admin-header .search-group {
    width: calc(100vw - 110px) !important;
  }

  .admin-header .searchSaleAcc {
    width: calc(100vw - 110px) !important;
  }
}

@media (max-width: 660px) {
  .admin-wrapper__page .admin-billing .admin-header {
    display: block;
  }

  .admin-wrapper__page .admin-billing .admin-header .search-group {
    width: calc(100vw - 150px);
  }
}

@media (max-width: 383px) {
  .admin-pagination {
    display: block;
  }

  .admin-pagination .pagination {
    margin-top: 10px;
    justify-content: center;
  }
}

.dropdown ul.dropdown-menu.show.dropdown-menu-right {
  top: 40px !important;
  right: 0px !important;
}

.goong-settings__item.security-box .security-box__qrcode__text .d-block p strong {
  word-wrap: break-word;
  vertical-align: baseline;
}

.paginationMobile {
  margin-top: 30px !important;
  text-align: center !important;
}

.admin-billing .create-keys__title .p1 strong {
  vertical-align: baseline;
}

.menu-key-table .dropdown-link:hover {
  background-color: #f5f5f5;
}

.p-explain {
  color: red !important;
  position: absolute;
  font-size: 14px;
}

.p-explain_dashboard {
  color: red !important;
  position: absolute;
  font-size: 14px;
  margin-top: 45px;
}

.modal-add-balance .label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  display: block;
}

.modal-add-balance .ant-form-item {
  margin-bottom: 16px;
}

.modal-add-balance .ant-input:placeholder-shown {
  text-transform: none !important;
}

.ant-result-image {
  margin: 0 auto !important;
}

.eye-icon-input {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  cursor: pointer;
}

.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0px 17px;
}

.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

@media (min-width: 1200px) {
  .header_fixed_margin_125 {
    margin-top: 125px;
  }

  .header_fixed_margin_95 {
    margin-top: 95px;
  }
}

.payment_display_flex {
  /* display: flex; */
  white-space: none;
}

.upgrades__btn .btn-light-blue:hover {
  background: #c3d6ee;
}

/* .billing-sidebar .ant-radio .ant-radio-wrapper  {
  white-space: none
} */

.email_bill_detail {
  word-wrap: break-word;
}

@media (max-width: 400px) {
  .email_bill_detail {
    width: 145px;
  }
}

.btn-statistics {
  margin: 8px;
}

.box-up_statistics {
  width: 1400px;
  height: 500px;
  background-color: #fff;
  position: fixed;
  left: calc(50% - 700px);
  border-radius: 12px;
  top: 20%;
}

.wrap_table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.table_statistics {
  width: 90%;
}

.col_th {
  border: 1px solid #ccc;
}

.wrap_next_page {
  text-align: center;
}

.list-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit {
  float: right;
}

@media (min-width: 700px) {
  .css_Status {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    margin-top: -15px;
  }

  .css_error {
    margin-left: 40px;
    margin-top: -10px;
  }

  .css_resule {
    margin-left: 10px;
    margin-top: -10px;
  }
}

@media (max-width: 700px) {
  .css_Status {
    width: 100%;
    align-items: center;
  }

  .css_error {
    margin-left: 10px;
    margin-top: -10px;
  }

  .css_resule {
    margin-left: 10px;
    margin-bottom: 8px;
  }
}

/* .Color_result{
  color: #54b62d;
  display: flex;
  br
} */
.PopUp_mobi_Header {
  width: 100%;
  /* display: flex; */
  /* justify-items:; */
}

.csv-button {
  margin-bottom: 13px;
}

.settings_rate_margin_top {
  margin-top: 24px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(240, 240, 240);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(182, 182, 182);
  padding: 5px 0px;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.ant-modal-content .ant-modal-header {
  padding: 24px 32px;
}

.model-campaign .ant-modal-content {
  background: transparent !important;
  box-shadow: none;
  /* border: 1px solid blue; */
  /* max-width: 420px; */
  width: 100% !important;
}

.model-campaign .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  display: none !important;
}

.model-campaign .ant-modal-content .ant-modal-body {
  border-top: none !important;
  padding: 0 !important;
}


.model-campaign .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
  padding: 0;
}

.model-campaign .ant-modal-close-x {
  font-size: 18px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 50%;
  background-color: rgb(207, 207, 207);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.campaign-mark {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-body {
  width: 70vh;
  height: 70vh;
  position: relative;
}

@media (max-width: 1023px) {
  .campaign-body {
    width: 50vh;
    height: 50vh;
    position: relative;
  }
}

@media (max-width: 500px) {
  .campaign-body {
    width: 30vh;
    height: 30vh;
    position: relative;
  }
}

.campaign-close-button-x {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: grey;
  cursor: pointer;
}


.campaign {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 8px;
  border: 1px solid red;
}


.campaign-slide-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 28px;
  /* max-width: 100px; */
}

.campaign-input-check {
  padding-left: 12px;
  height: 30;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;

}

.input-campaign {
  position: relative;
  cursor: pointer;
  /* transform: scale(1.2); */
}

.input-campaign:before {
  content: "";
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0;
  left: 0;
  border-radius: 0px;
  background-color: rgb(143, 143, 143);
}

.input-campaign:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 12px;
  border: solid rgb(0, 0, 0);
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -2px;
  left: 5px;
}

.input-campaign-p {
  margin: 0;
  font-size: 14px;
  color: grey;
  font-weight: 400;
}

.ant-modal-header .ant-modal-title {
  font-size: 28px;
  line-height: 28px;
}

.ant-modal-content .ant-modal-footer {
  padding: 20px 18px;
}

.admin-billing .admin-header {
  justify-content: flex-start;
}

.height_Option {
  height: 44px;
}

@media (min-width: 700px) {
  .total_footer {
    margin-top: -38px;
  }
}

.total_footer {
  margin-left: 10px;
}

.copy_key {
  width: 100%;
}

.copy_copy {
  /* margin-top: ; */
  /* margin-top: -54%; */
  float: right;
}

.test_copy {
  height: 45px;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* width: 100%; */
  /* max-width: 310px; */
}

.ant-table-cell {
  padding: 0;
  height: 5px;
}

.goong-statis__body_pie {
  margin-top: 125px;
}

.Fix_popUp_de {
  position: fixed;
  top: -53px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}

.account-pricing_plans {
  font-weight: 500;
  font-size: 28px;
  /* line-height: 33px; */
  color: #1f3349;
}

.wrap_pricing_plans {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.btn_pricing {
  font-size: 16px;
  color: #fff;
  background-color: #469af7;
  padding: 22px 18px;
  height: 8px;
  text-align: center;
  line-height: 1px;
  border-radius: 8px;
  font-weight: 500;
}

.h5_margin {
  margin: 0;
}

.price_Service {
  font-size: 17px;
  font-weight: 500;
  color: #4b9df7;
}

.price_Service_volume {
  font-size: 17px;
  color: #469af7;
  font-weight: 450 !important;
  margin: 0;
}

.price_Service_volume_5 {
  font-size: 15px;
  font-weight: 600 !important;
  margin: 0;
}

.Price_type {
  font-weight: 500;
  font-size: 15px;
  color: #2270b7;
}

.price_name {
  font-size: 15px;
  font-weight: 600;
}

.Switch_price {
  color: #4b9df7;
  font-size: 14px;
  font-weight: 500;
}

.about_services {
  font-size: 13px;
  font-weight: 600;
  margin-top: 3px;
  color: #454545;
}

.price_Header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.mar_price {
  margin: 0 8px;
}

.mar_price_number {
  margin: 0 22px;
}

/* .header_popup_price #header_popup_price {
  padding: 18px 28px;
} */
.Volume_services {
  font-size: 16px;
}

.goong_margin_right {
  margin-left: -20px;
}

.google_margin_left {
  margin-left: 30px;
}

@media (max-width: 1000px) {
  .acc-statistic {
    flex-direction: column;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .acc-statistic .selectTypee {
    margin-top: 0px !important;
    margin-left: 7px !important;
  }

  .acc-statistic .lcByCustom {
    margin-bottom: 0.5em !important;
  }
}

.create_map {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  /* width: 100%; */
}

@media (max-width: 825px) {
  .create_map {
    justify-content: center;
  }
}

.billing-list__table .create_map_item {
  width: 222px;
  height: 232px;
  margin: 12px 5px 12px 5px;


  border-radius: 3px;
}

.billing-list__table .create_map_item_shadow_red {
  box-shadow: 0 4px 6px 0 rgba(251, 17, 17, 0.3);
  border: 1px solid #f87676;
}

.billing-list__table .create_map_item_shadow_black {
  box-shadow: 1px 4px 6px 1px rgba(93, 93, 93, 0.3);
  border: 1px solid #aeaeae;
}

.create_map .create_map_item_list {
  margin-top: 3px;
  background-color: #ffffff;
}

.create_map .create_map_item_red {
  color: #fb4d4d;
}

.create_map .create_map_item_black {
  color: #494949;
}

.create_map .create_map_item_list_date {
  color: #494949;
  margin-right: 10%;
}

.billing-list__table .create_map_item .map {
  width: 220px;
  height: 165px;
}

.billing-list__table .create_map_item .item_text {
  width: 220px;
  height: 65px;
  padding: 1px 8px 2px 8px;
  display: flex;
  justify-content: space-between;
}

/* .map_detail {
  width: 100vw;
  height: 90vh;
} */

.goong-map {
  width: 100%;
  height: 100vh;
}

#map {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;
}

.notification-popup {
  width: 150px;
  height: 40px;
  position: absolute;
  top: 20px;
  background-color: #fff;
  right: calc(50% - 75px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

}

.mapboxgl-popup-close-button {
  font-size: 20px;
  right: 4px;
}

.popup-input-marker {
  width: 270px;
  margin-bottom: 12px;
  outline: none;
  border: 1px solid rgb(216, 216, 216);
  outline: none;
}

.mapboxgl-popup {
  min-width: 300px !important;
}

.mapboxgl-popup-content {
  min-width: 300px !important;
  border-radius: 10px;
  min-height: 150px;
  padding: 20px 12px 12px 12px;
}

.popup-input-button {
  width: 72px;
  height: 28px;
  border: none;
  cursor: pointer;
}

.popup-input-des {
  height: 50px;
}

.input-btn-save {
  background-color: #0070b7;
  color: white;
}

.search-box {
  position: absolute;
  top: 110px;
  left: 325px;
  display: flex;
  align-items: center;
}

.search-result {
  position: absolute;
  top: 150px;
  left: 325px;
  width: 400px;
  background-color: white;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  z-index: 2;
  border-radius: 2px;
  padding: 8px;
}

.result-box {
  width: 100%;
  cursor: pointer;
  margin: 0 0 8px 0;
}

.result-box:hover {
  background-color: rgb(221, 221, 221);
}


.action-box {
  position: absolute;
  top: 155px;
  left: 325px;
  display: flex;
  align-items: center;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  cursor: pointer;
}

.action-add-marker {
  width: 25px;
  height: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box-input {
  width: 400px;
  min-height: 35px;
  border: none;
  padding: 1px 8px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  outline: none;
  border-radius: 2px 0 0 2px;
}

.search-box-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 35px;
  background-color: #1677ff;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

@media (max-width: 800px) {
  .map_detail {
    width: 100vw;
    height: 100vh;

  }
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.bl_add_layer {
  position: absolute;
  top: 110px;
  left: 15px;
  background-color: white;
  align-items: center;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  width: 300px;
  border-radius: 3px;
  max-height: calc(100vh - 150px);
}

.layer-alert-tooltip {
  font-size: 12px;
}






@media (max-width: 1209px) {
  .bl_add_layer {
    position: absolute;
    top: 20px;
    left: 70px;
    background-color: white;
    align-items: center;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
    width: 300px;
    border-radius: 3px;
    max-height: calc(100vh - 50px);
  }

  .search-box {
    position: absolute;
    top: 20px;
    left: 385px;
    display: flex;
    align-items: center;
  }

  .action-box {
    position: absolute;
    top: 60px;
    left: 385px;
    display: flex;
    align-items: center;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  }

  .action-add-marker {
    width: 30px;
    height: 30px;
    background-color: white;
  }

  .search-box-input {
    width: 300px;
    min-height: 35px;
    border: none;
    padding: 1px 8px;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
    outline: none;
    border-radius: 2px 0 0 2px;
  }


  .search-box-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 35px;
    background-color: #1677ff;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
    border-radius: 0 2px 2px 0;
  }

}

.bl_header_layer {
  width: 100%;
  height: 100%;
  position: relative;
}

.bl_header-infor {
  width: 100%;
  padding: 12px 12px 24px 16px;
}

.bl_header-infor-name {
  display: flex;
  align-items: center;
  height: 20px;
}



.bl_header-infor-des {
  cursor: pointer;
}

.bl_header-option {
  width: 100%;
  height: 35px;
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 12px;
  background-color: rgb(235, 235, 235);
  padding: 0 16px;
  cursor: pointer;
  justify-content: space-between;
}

.bl_option-btn {
  display: flex;
  gap: 6px;
  align-items: center;
}

.btn-a,
.btn-a:active,
.btn-a:hover {
  color: #000;
  font-size: 12px;
}

.btn-a:active,
.btn-a:hover {
  color: #000;
  text-decoration: underline;
  font-size: 12px;
}


.bl_list {
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-height: calc(100vh - 270px);
  /* border: 1px solid red */
}

.map-base {
  display: flex;
  width: 100%;
  height: 30px;
  padding: 0 16px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.embed {
  width: 500px;
}


.popup-input {
  position: relative;
  cursor: pointer;
}

.popup-input:before {
  content: "";
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0;
  left: 0;
  border: 1px solid #cfcfcf;
  border-radius: 0px;
  background-color: white;
}

.popup-input:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 12px;
  border: solid rgb(0, 0, 0);
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -2px;
  left: 7px;
}

.bl_alert {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 20px;
  margin-bottom: 15px;
}

.layer-alert {
  padding: 8px;
  background-color: #f9edbe;
  color: #000;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, .1);
  border: 1px solid #ffc299;
  padding: 8px !important;
}

.layer-infor-a {
  text-decoration: underline;
}

.layer-infor-a:hover {
  text-decoration: underline;
}

.layer-alert-p {
  margin: 0;
  font-size: 13px;
  line-height: normal
}

.box {
  display: flex;
  width: 16px;
  height: 16px;
  border: 1px solid rgb(218, 218, 218);
  justify-content: center;
  align-items: center;
}

.box:hover {
  background-color: rgb(231, 231, 231);
}

.map-base-p {
  padding: 0;
  margin: 0;
}

.marker-popup {
  width: 100%;
  height: 100%;
  position: relative;
}


/* .popup--v1 {
  width: 100%;
  min-width: 300px;
  min-height: 140px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  position: absolute;
  z-index: 11 !important;
} */

.popup-boundary {
  height: 100%;
  width: 100%;
  padding: 12px 20px 12px 12px;
  background-color: white;
  border-radius: 10px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid rgb(255, 255, 255);
  position: absolute;
  bottom: -10px;
  right: calc(50% - 12px);
  z-index: 2;
}

.popup-handle {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.popup-content {
  width: 100%;
  height: 100%;

}

.popup-content-p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  width: 270px;
  word-wrap: break-word;
}

.popup-content-p-search {
  white-space: nowrap;
  width: 100%;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  margin: 0 0 8px 0;
  font-weight: bold;
}

.popup-content-des {
  font-size: 13px;
  font-weight: 200;
  min-height: 80px;
  margin: 0 0 6px 0;
  padding-top: 6px;
  word-wrap: break-word;
}

.popup-description {
  margin: 0 0 6px 0;
  font-weight: 200;
}

.popup-detail-a {
  color: #1677ff;
}

.popup-content-des-search {
  font-size: 12px;
  /* border: 1px solid red; */
  min-height: 70px;
  margin: 0 0 8px 0;
  padding-top: 6px;
  background-color: #f1f1f1;
  padding: 12px;
  border-radius: 2px;
  color: grey;
}

.popup-content-search-details {
  font-weight: bolder;
  margin: 0;
  color: rgb(88, 88, 88);
}

.popup-longLat {
  display: flex;
  gap: 4px;
}

.popup-content-latlng {
  font-size: 11px;
  margin: 0;
  color: grey;
  white-space: nowrap;
  max-width: 70px;
  overflow: hidden;
  text-overflow: clip;
  box-sizing: border-box;
}

.popup-content-handle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px
}


#save {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 28px;
  cursor: pointer;
  background-color: #0070b7;
  color: white;
  border-radius: 2px;
}

#cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 28px;
  cursor: pointer;
  background-color: #dbdbdb;
  color: black;
  border-radius: 2px;
  font-weight: bold;
}

#escape {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.escape {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}


#name {
  width: 100%;
  margin-bottom: 12px !important;
  outline: none;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 2px;
}

#description {
  width: 100%;
  outline: none;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 2px;
  margin-bottom: 12px !important;
}

.input-feild {
  width: 100%;
  margin-bottom: 12px;
}

.marker-popup-description {
  display: flex;
  /* width: 100%;
  height: 100%;
  max-width: 200px; */
  gap: 10px;
  /* overflow-y: scroll;
  scroll-behavior: smooth; */
}

.marker-popup-p {
  width: 250px;
  word-wrap: break-word;
  font-weight: 200;
}

.marker-popup-h3 {
  font-weight: 600;
  font-size: 16px;
}

.marker-popup-h4 {
  font-weight: 600;
  font-size: 13px;
}

.pick-style {
  width: 165px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

}

.input_point {
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  width: 100px;
}

._react-file-reader-input {
  width: 100px;
}

.layer-expand-h4 {
  font-size: 12px;
  color: #1677ff;
  margin: 6px 0;
  cursor: pointer;
}

.layer-expand-h4:hover {
  text-decoration: underline;
}

.layer-expand-name {
  display: flex;
  gap: 4px;
  padding: 0 0 0 16px;
  margin-top: 4x;
  align-items: start;
}

.style-map {
  width: 45px;
  height: 45px;
  border: 1px solid rgb(197, 197, 197);
  cursor: pointer;
}

.layer_popup {
  width: 100%;
  padding: 12px 10px 12px 12px;
  border-bottom: 1px solid #e5e5e5;
  border-left: 5px solid transparent;
  cursor: pointer;
}

.selected-layer_popup {
  width: 100%;
  padding: 12px 10px 12px 12px;
  border-bottom: 1px solid #e5e5e5;
  border-left: 5px solid #1677ff;
}

.layer_popup:hover {
  width: 100%;
  padding: 12px 10px 12px 12px;
  border-bottom: 1px solid #e5e5e5;
  border-left: 5px solid rgb(221, 221, 221);
}

.layer_popup .layer_name {
  display: flex;
  align-items: center;
}

.layer_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layer-details {
  display: flex;
  align-items: center;
  gap: 8px
}

.layer-details-h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.layer-details-p {
  white-space: nowrap;
  width: 100%;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.layer-expand {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.layer-expand-details {
  width: 100%;
  padding: 0 22px;
}

.layer_child-latlng--p {
  font-size: 12px;
}


.create_layer_popup {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 12px;
  background-color: white;
}

.item_text .ant-btn-link {
  width: 20px;
  padding: 4px 16px 2px 2px;
}

.layer_child {
  padding-left: 16px;
  width: 100%;
}


.selectedChildItem {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  background-color: rgb(231, 231, 231);
  width: 250px;
  height: 25px;
  box-sizing: border-box;
  margin-bottom: 4px;
}


.layer_child-name {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  width: 250px;
  height: 25px;
  background-color: transparent;
  box-sizing: border-box;
  margin-bottom: 4px;
}

.layer_child-name :hover {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  width: 250px;
  height: 25px;
  box-sizing: border-box;
  margin-bottom: 4px;
}


.selectedChildItem--p {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-bottom: 4px;
  color: #686868;
  cursor: default;
  padding-left: 24px;
  font-size: 13px;
  line-height: normal;
  font-weight: 500;
}


.layer_child-name--p {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-bottom: 4px;
  color: #a1a1a1;
  cursor: default;
  padding-left: 24px;
  font-size: 13px;
  line-height: normal;
}

.layer_header-infor--p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  /* margin-bottom: 4px; */
  color: #000;
  cursor: pointer;
}

.layer_des-infor--p {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 12px;
  color: grey;
  cursor: pointer;
}

.layer-action {
  display: flex;
  justify-content: center;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.layer_expand-name--p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-bottom: 4px;
  color: #000;
  cursor: pointer;
  font-weight: 400;
}

.layer-expand-name-folder {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
}


.layer_expand-name--span {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-bottom: 4px;
  color: #999999;
  cursor: default;
  font-weight: 500;
  font-size: 12px;
}


.layer_child-latlng--p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-bottom: 4px;
  color: grey;
  cursor: default;
}

.marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
}

.hoverMarker {
  background-color: red;
}

.marker:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(225, 225, 225, 0.9);
  border: 2px solid white;
}

.popup-marker-v1 {
  width: 300px;
  height: 150px;
  border: 1px solid red;
  position: absolute;
  z-index: 999999999999999999999;
}

.popup-test {
  width: 50px;
  height: 50px;
  border: 1px solid red;
  position: absolute;
  z-index: 9999999999999999999999;
}

.marker-off {
  display: none;
}


.markerIcon {
  font-size: 23px;
  color: rgb(160, 36, 36);
}

.popover {
  width: 280px;
}

.handle {
  width: 250px;
  padding: 4px 0 4px 16px;
  margin-left: -16px;
}

.handle:hover {
  background-color: #dddddd;
  cursor: pointer;
  margin-left: -16px;

}

.ant-popover-content {
  margin-top: -16px !important;
  border: 1px solid rgb(223, 223, 223);
}

.ant-popover-inner-content {
  padding: 8px 16px !important;
  max-width: 250px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.edit-input {
  width: 100%;
  outline: none;
  border-radius: 0px;
  border: 1px solid rgb(212, 212, 212);
}

.update-map-label {
  font-size: 13px;
  margin-top: 12px;
  font-weight: 500;
}



.create_map .map_empty_data {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 20vh;
  align-items: center;
}

.create_map .ant-list {
  width: 100%;
  /* background-color: #f4f4f4; */
}

.name_of_map {
  color: black;
  font-size: 15px;
  font-weight: 400
}

.date_of_map {
  color: #838383;
  font-size: 13px;
  font-weight: 300;
  line-height: 21px;
}

.key_of_map {
  color: #838383;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

.billing-list__header .view_mode {
  width: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 600px) {
  .billing-list__header .view_mode {
    margin-top: -12px;
  }
}


.support-modal .ant-modal-body {
  /* border: 1px solid green; */
  padding: 0 24px;
}

.support-modal .ant-modal-header {
  border-bottom: none;
  margin: 0;
}

.support-modal .ant-modal-footer {
  border-top: none;
}

.popup_support {
  margin-bottom: 16px;
}

.popup-download {
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  gap: 12px
}

.support-btn {
  width: 100px;
  height: 30px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #f0f0f0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.support-h1 {
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.support-h3 {
  font-size: 17px;
  font-weight: 400;
}

.support-content {
  border: 1px solid rgb(194, 194, 194);
  padding: 12px;
  border-radius: 10px;
  position: relative;
}

.support-li {
  display: flex;
  align-items: center;
  gap: 4px;
}

.support-img {
  position: absolute;
  top: 100px;
  right: 20px;
  border: 1px solid rgb(194, 194, 194);
  width: 300px;
  padding: 12px;
  border-radius: 10px;
  /* background-color: rgb(247, 215, 211); */
}

.support-p {
  font-size: 15px;
  font-weight: bold !important;
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

/* .support-ul .support-li {
  margin-bottom: 4px;

} */

.support-p-note {
  font-size: 15px;
  font-weight: bold !important;
  margin-bottom: 4px !important;
}

.popup_recycle .ant-spin-container {
  width: 98%;
}

@media (max-width: 600px) {
  .popup_recycle .ant-spin-container {
    width: 95%;
  }
}

.create_map .create_map_item_list .create_map_item_list--p {
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create_map .name_of_map--p {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.change-avatar .upload_image_popup {
  display: flex;
  align-items: center;
  margin: 6px;
  justify-content: space-between;
  background-color: #f6faff;
}

.ant-popover .ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  z-index: 1001 !important;
}

.edit_campaign .ant-select-selection-placeholder {
  color: rgb(56, 54, 54);
}

.edit_campaign .ant-picker-input>input::placeholder {
  color: rgb(56, 54, 54);
}

.edit_campaign .textarea_scroll_campaign {
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  height: 32px;
  line-height: 1.5715;
  padding: 3px 10px;
  background-color: transparent;
  ;
}

.input-copy {
  width: 100%;
  outline: none;
  border: 1px solid rgb(207, 207, 207);
}
.change_email .ul_item {
  font-weight: 500;
  color: red;
  font-size: 21px;
}
.change_email .li_item {
  margin-left: 24px;
  font-size: 14px;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  pointer-events: none; 
  background-color: #f5f5f5; 
}